import { 
	RECEIVE_OFFICER_RECORD,
	RECEIVE_OFFICE_RECORD,
	RECEIVE_OFFICE_SUB_RECORD,
	RECEIVE_ALL_OFFICER_BY_AFF_PK,
	REFETCH_DATA
} from "redux/actions/OfficerActions";

const initialState = {
	affInfo: {},
	officeInfo: [],
	officeSubInfo: [],
	allOfficerDetailByAffPK: [],
	refetchData: true
};

export const officerReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_OFFICER_RECORD: {
			return {
				...state,
				affInfo: action.payload
			}
		};
		case RECEIVE_OFFICE_RECORD: {
			return {
				...state,
				officeInfo: action.payload
			}
		};
		case RECEIVE_OFFICE_SUB_RECORD: {
			return {
				...state,
				officeSubInfo: action.payload
			}
		};
		case RECEIVE_ALL_OFFICER_BY_AFF_PK: {
			return {
				...state,
				allOfficerDetailByAffPK: action.payload,
				refetchData: false
			}
		};
		case REFETCH_DATA: {
			return {
				...state,
				refetchData: true
			}
		};
		default:
			return state;
	}
}