import React from "react";
import { SearchIcon } from "assets/icons/Icons";

export default function Input({ type, name, register, errors }) {
  return (
    <div>
      <input
        className="border w-full h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
        type={type}
        name={name}
        autoComplete="none"
        {...register(name, {
          required: true,
        })}
      />
      {errors[name] && (
        <p className="float-right text-xs text-red-500">
          {errors[name].message}
        </p>
      )}
    </div>
  );
}

export const SearchInput = () => {
  return (
    <div className="relative hidden">
      <input
        className="border w-full pl-10 h-10 xl:h-12 xl:text-lg rounded-md text-base outline-none px-2 focus:border-blue-400 shadow-md"
        placeholder="Search"
        type="text"
      />
      <button className="absolute text-gray-600 top-0 bottom-0 left-2">
        <SearchIcon />
      </button>
    </div>
  );
};
