import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import logo from "assets/images/logo.png";
import { useForm, Controller } from "react-hook-form";
import TextInput, { EmailInput, PhoneInput } from "./TextInput";
import { Button, DisableButton, GreenButton } from "components/Button";
import {
  fetchAffliatessAPI,
  fetchSelectSublocalAPI,
  fetchStateAPI,
  reigsterUserData,
} from "services/register";
import SelectItem from "pages/register/components/SelectItem";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { schemaSignUp } from "utils/schema";
import { ToastContainer } from "react-toastify";
import { notifySuccess, notifyError } from "utils/helper";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  VALIDATE_ERROR_MESSAGE,
} from "utils/constants";
import Honeypot from "components/Honeypot";

export default function Form() {
  let navigate = useNavigate();
  const [dataState, setDataState] = useState([]);
  const [dataAffliates, setDataAffliates] = useState([]);
  const [dataSublocal, setDataSublocal] = useState([]);

  const [listSelect, setListSelect] = useState([]);

  const [selectState, setSelectState] = useState("");
  const [selectAffliates, setSelectAffliates] = useState("");
  const [selectSublocal, setSelectSublocal] = useState("");

  const [loading, setLoading] = useState(false);

  const [isSpam, setIsSpam] = useState(false);

  const {
    control,
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSignUp),
  });

  const addSelectData = (data) => {
    if (data.key && data.state) {
      if (!listSelect.find((el) => el.key && el.state === data.state)) {
        const filteredResult = listSelect.filter(
          (el) => el.state !== data.state
        );
        setListSelect([...filteredResult, data]);
      }
    } else {
      if (
        !listSelect.find(
          (el) =>
            el.aff_pk === data.parent ||
            el.aff_pk === data.aff_pk ||
            (el.key && el.state === data.state)
        )
      ) {
        const filteredresult = listSelect.filter(
          (el) => el.parent !== data.aff_pk
        );

        setListSelect([...filteredresult, data]);
      }
    }
  };
  const getStates = async () => {
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
  };

  const getAffliates = async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([
            { value: "all", label: "All", state: selectState.value },
          ]);
        } else {
          const selectAffliatesData = response?.data
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([
            { value: "all", label: "All", state: selectAffliatesData[0].state },
            ...selectAffliatesData,
          ]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  };
  const getSublocal = async () => {
    try {
      const response = await fetchSelectSublocalAPI({
        data: { state: selectState.value, affiliate: selectAffliates.affPk },
      });
      if (response && response.data) {
        if (response.data.length === 0) {
          setDataSublocal([{ value: "all", label: "All" }]);
        } else {
          const selectSublocalData = response?.data
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: `${item.short_name}`,
                label: `${item.short_name}`,
                affPK: item?.aff_pk,
                parent: item?.parent,
              };
            });
          setDataSublocal([
            { value: "all", label: "All" },
            ...selectSublocalData,
          ]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error: ", error);
    }
  };

  const handleSelectState = (event) => {
    setSelectState(event);
    setSelectAffliates("");
  };

  const handleSelectAffliates = (event) => {
    console.log(event);
    // const isDupOption = listSelect.some(
    //   (currentValue) =>
    //     currentValue.key === "all" && currentValue.state === event.state
    // );

    if (event.value === "all") {
      // const newListSelect = listSelect.filter((item) => {
      //   return item.state !== event.state;
      // });
      // setListSelect([
      //   ...newListSelect,
      // {
      //   state: selectState,
      //   shortName: "",
      //   subLabel: "",
      //   key: "all",
      // },
      // ]);
      addSelectData({
        state: selectState.value,
        shortName: "",
        subLabel: "",
        key: "all",
      });
      setSelectAffliates(event);
      setSelectSublocal("");
      return;
    }
    if (event.type === "L" || event.type === "R") {
      addSelectData({
        state: selectState.value,
        shortName: event.short_name,
        subLabel: "",
        aff_pk: event.aff_pk,
        parent: event.parent,
      });
    }
    setSelectAffliates(event);
    setSelectSublocal("");
  };

  const handleSelectSublocal = (event) => {
    if (event.value === "all") {
      // setListSelect([
      //   ...listSelect,
      //   {
      //     state: selectState,
      //     shortName: selectAffliates.short_name,
      //     subLabel: "",
      //     aff_pk: selectAffliates.aff_pk,
      //     parent: selectAffliates.parent,
      //   },
      // ]);
      addSelectData({
        state: selectState.value,
        shortName: selectAffliates.short_name,
        subLabel: "",
        aff_pk: selectAffliates.aff_pk,
        parent: selectAffliates.parent,
      });
    } else {
      // setListSelect([
      //   ...listSelect,
      //   {
      //     state: selectState,
      //     shortName: selectAffliates.short_name,
      //     subLabel: event.short_name,
      //     aff_pk: event.aff_pk,
      //     parent: event.parent,
      //   },
      // ]);
      addSelectData({
        state: selectState.value,
        shortName: selectAffliates.short_name,
        subLabel: event.short_name,
        aff_pk: event.aff_pk,
        parent: event.parent,
      });
    }
    setSelectSublocal(event);
  };

  const handleCheckSpam = (event) => {
    if (!isSpam) {
      setIsSpam(true);
    }
  };

  const onSubmit = async (data) => {
    if (!isSpam) {
      setLoading(true);
      try {
        const { email, firstName, lastName, phone } = data;
        const dataSubmit = {
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          affiliates: listSelect.map((el) =>
            el.aff_pk ? { aff_pk: el.aff_pk } : { state: el.state }
          ),
        };
        if (listSelect.length < 1) {
          notifyError(VALIDATE_ERROR_MESSAGE);
          return;
        }
        const result = await reigsterUserData({
          data: { request: dataSubmit },
        });
        setLoading(false);
        if (result && result.status === 200) {
          notifySuccess(SUCCESS_MESSAGE);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      } catch (err) {
        notifyError(ERROR_MESSAGE);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("spamm");
    }
  };

  // delete select item
  const handleDeleteItem = (value) => {
    const listAfterDelete = listSelect.filter((item) => {
      if (value.aff_pk) {
        return item.aff_pk !== value.aff_pk;
      } else {
        return !(!item.aff_pk && item.state === value.state);
      }
    });

    setListSelect(listAfterDelete);
  };

  // clear form
  const handleClear = () => {
    resetField("firstName");
    resetField("lastName");
    resetField("email");
    resetField("phone");
    setListSelect([]);
    setSelectState("");
    setSelectAffliates("");
    setSelectSublocal("");
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (selectState) {
      getAffliates();
    }
  }, [selectState]);

  useEffect(() => {
    if (selectAffliates) {
      if (selectAffliates.value !== "all") {
        getSublocal();
      } else {
        setDataSublocal([]);
      }
    }
  }, [selectAffliates]);

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center px-3">
      <ToastContainer />
      <div className="flex drop-shadow-2xl">
        <div className="bg-white py-4 min-w-[310px] md:min-w-[400px] rounded-sm sm:rounded-r-none">
          <div className="py-8 px-4">
            <img className="w-1/2 h-auto mx-auto" src={logo} alt="" />
            <p className="text-gray-500 text-center text-sm sm:text-[1.1rem]">
              Officer Information Management System
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-4 h-auto">
              <div className="pb-10">
                <TextInput
                  label="First name:"
                  name="firstName"
                  register={register}
                  errors={errors}
                />
                <TextInput
                  label="Last name:"
                  name="lastName"
                  register={register}
                  errors={errors}
                />
                <EmailInput
                  label="Email:"
                  name="email"
                  register={register}
                  errors={errors}
                />

                <PhoneInput
                  label="Phone:"
                  name="phone"
                  register={register}
                  errors={errors}
                />
              </div>
              <p className="text-sm mb-3 italic text-center text-grey">
                I need access to the following states/councils/locals:
              </p>
              {/* Select state */}
              <div className="flex items-center justify-end pb-5 md:pr-[1rem]">
                <p className="pr-2 md:text-sm text-[0.8rem]">State:</p>
                <Controller
                  name="state"
                  render={({ field }) => (
                    <Select
                      name="selectState"
                      className="text-sm w-[71%] md:w-3/4"
                      {...field}
                      options={dataState}
                      onChange={handleSelectState}
                      value={selectState}
                    />
                  )}
                  control={control}
                />
              </div>

              {/* Select affliates */}
              <div className="flex items-center justify-end pb-5 md:pr-[1rem]">
                <p className="pr-2 md:text-sm text-[0.8rem]">Affliates:</p>
                <Controller
                  name="affliates"
                  render={({ field }) => (
                    <Select
                      className="text-sm w-[71%] md:w-3/4"
                      {...field}
                      options={dataAffliates}
                      isDisabled={selectState.length === 0}
                      onChange={handleSelectAffliates}
                      value={selectAffliates}
                    />
                  )}
                  control={control}
                />
              </div>

              {/* Select Sublocal*/}
              <div className="flex items-center justify-end pb-5 md:pr-[1rem]">
                <p className="pr-2 md:text-sm text-[0.8rem]">Local:</p>
                <Controller
                  name="sublocal"
                  render={({ field }) => (
                    <Select
                      className="text-sm w-[71%] md:w-3/4"
                      {...field}
                      options={dataSublocal}
                      isDisabled={
                        selectAffliates.value === "all" ||
                        selectAffliates.length === 0 ||
                        selectAffliates.type === "L" ||
                        selectAffliates.type === "R"
                      }
                      onChange={handleSelectSublocal}
                      value={selectSublocal}
                    />
                  )}
                  control={control}
                />
              </div>

              {/* box list select */}
              <div className="max-w-[300px] md:max-w-[368px] flex justify-center">
                <div className="h-24 p-2 border rounded-sm shadow-sm flex flex-1 flex-wrap overflow-scroll overflow-y-auto overflow-x-hidden">
                  {listSelect.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="cursor-pointer"
                        onClick={() => handleDeleteItem(item)}
                      >
                        <SelectItem
                          label={`${item.state} ${
                            item.shortName ? "- " + item.shortName : ""
                          } ${item.subLabel ? "- " + item.subLabel : ""}`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <Honeypot register={register} handleCheckSpam={handleCheckSpam} />

              <div className="flex justify-between font-san pt-4">
                <div onClick={handleClear}>
                  <Button text="Clear" color="bg-red-10" />
                </div>
                <div className="flex flex-col justify-center items-center">
                  {loading ? (
                    <DisableButton text="Register" />
                  ) : (
                    <GreenButton text="Register" />
                  )}
                  <Link className="text-blue-500 text-sm py-3" to="/login">
                    Sign in
                  </Link>
                </div>
              </div>
              <div className="flex justify-end"></div>
            </div>
          </form>
        </div>

        <div className="hidden sm:block bg-img max-w-[600px] rounded-r-sm">
          <div className="bg-slate-600-30 h-full flex items-center justify-center">
            <p className="text-white px-6 md:px-10 lg:px-12 sm:text-2xl md:text-3xl text-center">
              For people proudly committed to the public service that makes
              America happen, we never quit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
