import React from "react";

function Honeypot({ register, handleCheckSpam }) {
  return (
    <div className="hidden border">
      <input
        {...register("contact", { required: true })}
        onChange={handleCheckSpam}
      />
      <input
        {...register("fullName", { required: true })}
        onChange={handleCheckSpam}
      />
      <input
        {...register("address", { required: true })}
        onChange={handleCheckSpam}
      />
    </div>
  );
}

export default Honeypot;
