import { editAff_pkTypes } from "utils/constants";

const initialState = {
  listAff_pk: [],
  listShort_name: [],
};

export const aff_pkReducer = (state = initialState, action) => {
  switch (action.type) {
    case editAff_pkTypes.ADD_AFF_PK:
      const addAff = state.listAff_pk;

      return {
        ...state,
        listAff_pk: [...addAff, action.payload],
      };

    case editAff_pkTypes.REMOVE_AFF_PK:
      const removeAff = state.listAff_pk.filter((item) => {
        return item !== action.payload;
      });
      return {
        ...state,
        listAff_pk: removeAff,
      };

    case editAff_pkTypes.CLEAR_AFF_PK:
      return {
        ...state,
        listAff_pk: [],
      };

    case editAff_pkTypes.SET_SHORT_NAME:
      const setShortName = state.listShort_name;

      return {
        ...state,
        listShort_name: [action.payload, ...setShortName],
      };

    case editAff_pkTypes.REMOVE_SHORT_NAME:
      const removeShortName = state.listShort_name.filter((item) => {
        return item !== action.payload;
      });
      return {
        ...state,
        listShort_name: removeShortName,
      };

    case editAff_pkTypes.CLEAR_SHORT_NAME:
      return {
        ...state,
        listShort_name: [],
      };

    default:
      return state;
  }
};
