import { IconArrowLeft, MenuIcon, WhiteLogo } from "assets/icons/Icons";

const Header = ({ showSideBar, setShowSideBar }) => {
  const handleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <header className="h-16 sm:pl-6 w-full relative bg-green-10 flex items-center">
      <div
        className="p-2 text-white cursor-pointer hover:scale-125 duration-300"
        onClick={handleShowSideBar}
      >
        <div className="relative">
          <div
            className={`absolute ${
              !showSideBar ? "opacity-100" : "opacity-0 rotate-90"
            } duration-300`}
          >
            <MenuIcon />
          </div>
          <div
            className={`${
              showSideBar ? "opacity-100" : "opacity-0 rotate-90"
            } duration-300`}
          >
            <IconArrowLeft />
          </div>
        </div>
      </div>
      {/* logo */}
      <div className="flex flex-1 justify-center items-center space-x-4">
        <div>
          <WhiteLogo />
        </div>

        <p className="text-white text-lg sm:text-2xl text-center">
          Officer Information Management System
        </p>
      </div>
    </header>
  );
};

export default Header;
