import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import authActions from "redux/actions/auth";
import { Button } from "components/Button";

function ChooseRoles() {
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state.auth);
  const currentRole = authInfo.role;

  const [roleApply, setRoleApply] = useState(currentRole);
  const [listAff_pk, setListAff_pk] = useState([]);

  const dataSelectRoles = authInfo?.roles?.map((item) => {
    return { ...item, label: item.role, value: item.role };
  });
  console.log("🚀 ===== dataSelectRoles", dataSelectRoles);

  const handleChangeRole = (value) => {
    setRoleApply(value.role);
  };

  const handleApplyRole = () => {
    if (currentRole !== roleApply) {
      dispatch(authActions.changeRoles({ role: roleApply }));
    }
  };

  useEffect(() => {
    const getListAff_pkCurrenRole = authInfo?.roles?.filter((item) => {
      return item.role === roleApply;
    })[0].affiliates;

    setListAff_pk(getListAff_pkCurrenRole);
  }, [authInfo.roles, roleApply]);

  return (
    <div className="pb-4">
      <p className="text-green-10 mb-3 border-l-2 border-green-10 pl-2">
        Change Roles
      </p>
      <div className="w-fit">
        <Select
          className="w-80"
          options={dataSelectRoles}
          onChange={handleChangeRole}
          defaultValue={{ label: currentRole, value: currentRole }}
        />

        <div className="mt-2 w-80 h-32 border rounded-lg shadow-sm p-2 overflow-auto">
          <p>{roleApply}:</p>
          <p className="ml-2 text-gray-500">
            Aff_PK:{" [ "}
            {listAff_pk?.map((item, index) => {
              if (index === listAff_pk.length - 1) {
                return <span key={item.aff_pk}>{item.aff_pk} </span>;
              }
              return <span key={item.aff_pk}>{item.aff_pk}, </span>;
            })}
            {" ]"}
          </p>
        </div>

        <div className="mt-2 float-right" onClick={handleApplyRole}>
          <Button
            text="Apply"
            color="bg-blue-500"
            disabled={currentRole === roleApply}
          />
        </div>
      </div>
    </div>
  );
}

export default ChooseRoles;
