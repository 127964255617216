import { getPendingRequestAPI } from "services/requestPending";

export const RECEIVE_USER_PENDING_REQUEST = 'RECEIVE_USER_PENDING_REQUEST';

export const getUserPendingRequest = () => async (dispatch) => {
	let list = [];
	const response = await getPendingRequestAPI();
	if (response && response.data) {
		for (const property in response.data) {
			if (!Array.isArray(response.data[property]))
				list.push(response.data[property]);
		}
	}
	dispatch({
		type: RECEIVE_USER_PENDING_REQUEST,
		payload: list,
	})
};