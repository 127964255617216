/** @format */

import React, { memo, useCallback } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { excelDateToJSDate } from "utils/helper";

import * as XLSX from "xlsx";

function ModalUploadFile({
  closeModal,
  setDataOfficer,
  handleLoadingFile,
  setLoading,
}) {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [isExcelFile, setIsExcelFile] = useState(true);

  const getDataTable = ({ dataWorksheet }) => {
    const dataTable = [];

    if (dataWorksheet[0].hasOwnProperty("__EMPTY_1")) {
      const startRow = 7;
      const table = dataWorksheet.slice(startRow);

      table.forEach((item, index) => {
        if (index < table.length) {
          dataTable.push({
            state: item?.__EMPTY,
            aff_type: item?.__EMPTY_1,
            council: item?.__EMPTY_2,
            local: item?.__EMPTY_3,
            subunit: item?.__EMPTY_4,
            officer_title: item?.__EMPTY_5,
            officer_name: item?.__EMPTY_6,
            enterprise_ID: item?.__EMPTY_7,
            election_date: excelDateToJSDate(item?.__EMPTY_8),
            expiration_date: excelDateToJSDate(item?.__EMPTY_9),
            home_address: {
              street: item?.__EMPTY_10,
              city: item?.__EMPTY_11,
              state: item?.__EMPTY_12,
              zip: item?.__EMPTY_13,
            },
            mailing_address: {
              street: item?.__EMPTY_14,
              city: item?.__EMPTY_15,
              state: item?.__EMPTY_16,
              zip: item?.__EMPTY_17,
            },
            send_mail: item?.__EMPTY_18,
            primary_phone_number: item?.__EMPTY_19,
            primary_phone_type: item?.__EMPTY_20,
            secondary_phone_number: item?.__EMPTY_21,
            secondary_phone_type: item?.__EMPTY_22,
          });
        }
      });
    } else {
      dataWorksheet.forEach((item, index) => {
        if (index < dataWorksheet.length) {
          dataTable.push({
            state: item?.state,
            aff_type: item["Affiliate Type(C,L,R,S)"],
            council: item["Council (or null)"],
            local: item["Local"],
            subunit: "",
            officer_title: item["Officer Title"],
            officer_name: item["Officer Name"],
            enterprise_ID: item["Enterprise ID"],
            election_date: excelDateToJSDate(item["Election Date"]),
            expiration_date: excelDateToJSDate(item["Expiration Date"]),
            home_address: {
              street: item["Officer's Home Address"],
              city: item["Officer's Home Address2"],
              state: item["Officer's Home Address3"],
              zip: item["Officer's Home Address4"],
            },
            mailing_address: {
              street: item["Officer's Mailing Address (if different)"],
              city: item["Officer's Mailing Address (if different)1"],
              state: item["Officer's Mailing Address (if different)2"],
              zip: item["Officer's Mailing Address (if different)3"],
            },
            send_mail:
              item[
                "Send Mail to Affiliate Office instead of officer's home?(Y or N)"
              ],
            primary_phone_number: item["Primary Phone Number"]
              ? item["Primary Phone Number"]
              : "",
            primary_phone_type: item["Primary Phone Type"],
            secondary_phone_number: item["Secondary Phone Number"],
            secondary_phone_type: item["Secondary Phone Type"],
          });
        }
      });
    }
    return dataTable;
  };

  const setOfficers = (dataTable) => {
    setLoading(true);
    handleLoadingFile(dataTable, false)
      .then((values) => {
        console.log(values);
        setDataOfficer(values);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // validate excel file
  const handleChange = useCallback((event) => {
    if (event?.target?.files[0]?.name) {
      const fileName = event?.target?.files[0]?.name;
      const typeFile = fileName.substring(fileName.lastIndexOf("."));
      if (typeFile === ".xlsx" || typeFile === ".xlsm" || typeFile === ".xls") {
        setIsExcelFile(true);
        const files = event.target.files,
          f = files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
          try {
            const workbook = XLSX.read(e.target.result);
            let first_sheet_name = workbook.SheetNames[0];
            const dataWorksheet = XLSX.utils.sheet_to_json(
              workbook.Sheets[first_sheet_name]
            );
            // get data officer from template excel file
            const dataTable = getDataTable({ dataWorksheet });

            setOfficers(dataTable);
            closeModal();
          } catch (error) {
            console.log("error", error);
          }
        };

        reader.readAsArrayBuffer(f);
      } else {
        setIsExcelFile(false);
      }
    }
  }, []);

  return (
    <div className="bg-slate-200/70 p-5 sm:p-8 rounded-b-md">
      <form>
        <div className="py-5 sm:px-5">
          <input
            type="file"
            name="file"
            {...register("file", { required: true })}
            className="cursor-pointer"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(event) => handleChange(event)}
          />
          {errors["file"]?.type === "required" && isExcelFile && (
            <p className="text-red-500 text-sm">
              Please select at least one excel file
            </p>
          )}
          {isExcelFile ? (
            ""
          ) : (
            <p className="text-red-500 text-xs">
              Please upload only an excel file
            </p>
          )}
        </div>
      </form>
    </div>
  );
}

export default memo(ModalUploadFile);
