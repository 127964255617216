import React from "react";
import { useState, useEffect } from "react";
import { formatDate } from "utils/helper";
import { ENUOfficerDetailCell } from "../cells/ENUOfficerDetailCell";
import { ENUShowMore } from "../cells/ENUShowMore";
import { ENUSpaceSmallest } from "components/ingredients";

export const ENUOfficerDetail = ({
	item,
	isActive,
	showModal
}) => {
	const [showMore, setShowMore] = useState(false);
	const [officerDetails, setOfficerDetails] = useState([]);
	
	const itemLength = item[
			`${isActive ? "officers" : "past_officers"}`
		].length;
	const initialDetails = item[
			`${isActive ? "officers" : "past_officers"}`
		].filter((el, ind) => (ind < 3));

	useEffect(() => {
		if (showMore) {
			setOfficerDetails(item[
				`${isActive ? "officers" : "past_officers"}`
			])
		} else {
			setOfficerDetails(initialDetails);
		}
	}, [showMore, item])

	useEffect(() => {
		if (itemLength < 10) {
			setShowMore(true);
		}
	}, []);

	return (
		<>
			{officerDetails.map((el, ind) => (
				<ENUOfficerDetailCell
					key={ind} 
					ind={ind}
					item={item}
					el={el}
					isActive={isActive}
					showModal={showModal}
				/>
			))}
			{itemLength > 3 &&
				<ENUShowMore
					showMore={showMore}
					count={itemLength > 3 ? itemLength - 3 : 0}
					text={showMore? "" : "(show)"}
					textColor="text-blue-600"
					bgColor="bg-blue-500"
					style={{
						paddingLeft: 40
					}}
					handleShowMore={() => setShowMore(true)
					}
				>
					{isActive && (
						<p className="font-normal pl-3 text-sm italic">
							{item?.officers?.length === 0 &&
								item?.past_officers?.length > 0 &&
								item?.seats === 1 &&
								`vacant since ${formatDate(
									item.past_officers[0].pos_end_dt * 1000
								)}`
							}
							{((item?.officers?.length === 0 &&
								item?.past_officers?.length === 0) ||
								(item?.officers?.length >= 0 &&
									item?.seats > 2 &&
									item?.officers?.length < item?.seats)) &&
								`${
									item?.seats - item?.officers?.length
								} vacant ${
									item?.seats - item?.officers?.length > 1
										? "seats"
										: "seat"
								} `
							}
							{item?.officers?.length > 0 &&
								item?.officers?.length > item?.seats &&
								`Over capacity: ${
									item?.officers?.length
								} active officers, but office has only ${
									item?.seats
								} ${item?.seats > 1 ? "seats" : "seat"}
							`}
						</p>
					)}
				</ENUShowMore>
			}
		</>
	)
}

