import React from "react";

export const ENULink = ({
	text,
	textColor,
	handleClick
}) => {
	return (
		<div
			className={`flex items-center w-fit rounded-lg h-5 mt-0.5 cursor-pointer hover:opacity-80 m-auto`}
			onClick={handleClick}
		>
			<p className={`px-2 text-[12px] ${textColor} truncate text-decorate underline`}>{text}</p>
		</div>
	);
}

