import CardRequest from "components/CardRequest";
import Layout from "components/layout";
import { useEffect, useState } from "react";
import { requestMessage } from "utils/constants";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { notifySuccess, notifyError } from "utils/helper";
import {
  responsePendingRequest,
} from "services/requestPending";
import { getUserPendingRequest } from "redux/actions/userRequestActions";
import Loading from "components/Loading";
import { DataIcon } from "assets/icons/Icons";

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const { userPendingRequests } = useSelector((state) => 
    state.userRequest
  );

  const [loading, setLoading] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  const getListPending = async () => {
    setLoading(true);
    await dispatch(getUserPendingRequest());
    setLoading(false);
  };

  const handleApprove = async (affiliates) => {
    const data = { accept: [affiliates] };
    setLoading(true);

    try {
      const response = await responsePendingRequest({ data });
      if (response) {
        notifySuccess(requestMessage.APPROVE_SUCCESS_MESSAGE);
        getListPending();
      }
      setLoading(false);
    } catch (error) {
      notifyError(requestMessage.APPROVE_ERROR_MESSAGE);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeny = async (affiliates) => {
    const data = { deny: [affiliates] };
    setLoading(true);
    try {
      const response = await responsePendingRequest({ data });
      if (response) {
        notifySuccess(requestMessage.DENY_SUCCESS_MESSAGE);
        getListPending();
      }
      setLoading(false);
    } catch (error) {
      notifyError(requestMessage.DENY_ERROR_MESSAGE);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (!userPendingRequests) {
      getListPending();
    }
    
  }, [dispatch]);

  useEffect(() => {
    if (userPendingRequests && userPendingRequests.length > 0) {
      setIsRequest(true);
    } else {
      setIsRequest(false);
    }
  }, [userPendingRequests]);

  return (
    <>
      <ToastContainer />
      <Loading isVisible={loading} />

      <div className="container m-auto w-full">
        {!isRequest ? (
          <>
            <div className="relative h-[80vh] flex justify-center items-center">
              <div className="text-sliver-10 flex flex-col justify-center items-center">
                <DataIcon size={64} />
                <p className="text-2xl">No pending requests</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3 2xl:px-10 my-6">
              {userPendingRequests.map((item, index) => {
                return (
                  <div key={index}>
                    <CardRequest
                      data={item}
                      handleApprove={() => handleApprove(item)}
                      handleDeny={() => handleDeny(item)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RegistrationForm;
