import { Button } from "components/Button";
import Loading from "components/Loading";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editOfficer } from "services/officer";
import { notifyError } from "utils/helper";
import { editOfficerDetail } from "redux/actions/OfficerActions";

function DeleteModal({ closeModal, dataDelete, getDataOfficer }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deleteOfficer = async () => {
    setLoading(true);
    try {
      await dispatch(editOfficerDetail({
        data: [
          { uuid: dataDelete.uuid, aff_pk: dataDelete.aff_pk, delete_fg: true },
        ],
      }));

      await getDataOfficer();
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    } finally {
      closeModal();
      setLoading(false);
    }
  };

  return (
    <div className="bg-slate-200/70 p-5 rounded-b-md overflow-auto">
      <Loading isVisible={loading} />

      <div className="">
        <div>
          <div className="text-center pb-4">
            Deleting this officer will remove their role as{" "}
            <b>
              {dataDelete.aff_short_name} {dataDelete.title}
            </b>{" "}
            from the officer history tables entirely and can not be undone. If
            this is not what you want to do, please instead EDIT the officer to
            add the desired End Date
          </div>
          <div className="flex space-x-10 justify-center">
            <div onClick={deleteOfficer}>
              <Button text={"Delete"} color={"bg-red-500"} />
            </div>
            <div onClick={closeModal}>
              <Button text={"Cancel"} color={"bg-blue-500"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
