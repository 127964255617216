import React, { useState } from "react";
import { DisableButton, GreenButton } from "components/Button";
import Input from "components/Input";
import logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import authActions from "redux/actions/auth";
import { schemaSignIn } from "utils/schema";
import { ToastContainer } from "react-toastify";
import { notifySuccess, notifyError } from "utils/helper";
import { SUCCESS_MESSAGE } from "utils/constants";

export default function Form() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSignIn),
  });

  const onSubmit = async ({ userName, password }) => {
    setLoading(true);
    try {
      const response = await Auth.signIn(userName, password);
      const setUserSession = await Auth.currentSession();
      const roles = JSON.parse(setUserSession.idToken.payload.roles);

      dispatch(
        await authActions.loginSuccess({
          isLoggedIn: true,
          email: response?.attributes?.email,
          name: response?.attributes?.name,
          role: roles[0].role,
          roles: roles,
        })
      );

      notifySuccess(SUCCESS_MESSAGE);
      setLoading(false);
    } catch (err) {
      notifyError(
        err?.message ? err.message : `Data Fetch Error : ${err.status} code`
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center">
      <ToastContainer />
      <div className="absolute flex justify-center my-auto top-0 bottom-0 px-3 mx-auto h-[610px] w-full">
        <div className="bg-white px-5 py-10 drop-shadow-2xl rounded-sm sm:rounded-r-[0] w-[440px] min-w-[290px] max-w-[650px]">
          <div className="py-10 flex flex-col items-center">
            <img className="w-[262px] h-auto" src={logo} alt="logo" />
            <p className="text-gray-500 w-10/12 text-center text-sm sm:text-[1.1rem]">
              Officer Information Management System
            </p>
          </div>

          {/* login form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-4">
              <div className="pb-4">
                <p className="font-medium text-sm mb-1">Email</p>
                <Input
                  type="text"
                  name="userName"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="pb-4">
                <p className="font-medium text-sm mb-1">Password</p>
                <Input
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="pb-4">
                <Link className="text-blue-500 text-sm" to="/forgot_password">
                  Forgot password
                </Link>
              </div>

              <div className="flex justify-center items-center font-san">
                {loading ? (
                  <DisableButton text="Log in" />
                ) : (
                  <GreenButton text="Log in" />
                )}
              </div>
              <div className="flex justify-center">
                <Link className="text-blue-500 text-sm py-3" to="/register">
                  Create new user
                </Link>
              </div>
            </div>
          </form>
        </div>

        <div className="hidden sm:block w-full max-w-[560px] drop-shadow-2xl">
          <div className="bg-slate-800-70 h-full flex justify-center items-center rounded-r-sm">
            <p className="text-white px-6 md:px-10 lg:px-12 sm:text-2xl md:text-3xl text-center">
              For people proudly committed to the public service that makes
              America happen, we never quit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
