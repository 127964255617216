import { Button } from "./Button";

const CardRequest = ({ data, handleApprove, handleDeny }) => {
  const affiliates = [];

  const sortAffiliates = () => {
    const listAffiliates = [];
    // state first
    data.affiliates.map((item) => {
      if (item.state) {
        return listAffiliates.unshift(item);
      }
      return listAffiliates.push(item);
    });
    listAffiliates.map((item) => {
      return affiliates.push(item.state || item.short_name);
    });
    // sort by aff_pk
    affiliates.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    });
  };

  sortAffiliates();

  return (
    <div>
      <div className="rounded-2xl p-4 bg-white shadow-md border hover:shadow-xl duration-300">
        <p className="font-bold text-black-10 lg:text-lg">
          {data?.first_name} {data?.last_name}
        </p>
        <p className="text-sm italic opacity-60 lg:text-base">{data?.email}</p>
        <div className="flex flex-wrap justify-start items-center py-2 h-20 overflow-auto overflow-x-hidden my-2">
          {affiliates.map((item, index) => {
            return (
              <div
                key={index}
                className="bg-blue-10 rounded-md text-white text-[10px] lg:text-xs py-1 px-2 mr-1 mb-1"
              >
                <p>{item}</p>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center space-x-4">
          <div onClick={handleApprove}>
            <Button text="Approve" color="bg-green-10" />
          </div>
          <div onClick={handleDeny}>
            <Button text="Deny" color="bg-red-10" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardRequest;
