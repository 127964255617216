import { api } from "services";
import { 
  getOfficerRecord, 
  getTreeHierarchy,
} from "services/officer";
import { getDataCouncil, getAllOfficersDetail } from "utils/office";
import { EDIT_OFFICER, EDIT_OFFICE } from "utils/request";

export const RECEIVE_OFFICER_RECORD = 'RECEIVE_OFFICER_RECORD';
export const RECEIVE_OFFICE_RECORD = 'RECEIVE_OFFICE_RECORD';
export const RECEIVE_OFFICE_SUB_RECORD = 'RECEIVE_OFFICE_SUB_RECORD';
export const RECEIVE_ALL_OFFICER_BY_AFF_PK = 'RECEIVE_ALL_OFFICER_BY_AFF_PK';
export const REFETCH_DATA = 'REFETCH_DATA';

export const fetchOfficerRecord = ( data ) => async (dispatch) => {
  const { data: affInfo } = await getOfficerRecord({ data: data });
  dispatch ({
    type: RECEIVE_OFFICER_RECORD,
    payload: affInfo,
  })
};

export const fetchOfficeRecord = ( data ) => async (dispatch) => {
  const { data: officeInfo } = await getTreeHierarchy({ data: data });
  dispatch ({
    type: RECEIVE_OFFICE_RECORD,
    payload: officeInfo,
  })
};

export const fetchOfficeSubLocalRecord = ( officeInfo ) => async (dispatch) => {
  let councilData = getDataCouncil(officeInfo);

  const promises = councilData.map((el) => getSubLocal(el.aff_pk));
  const officeSubInfo  = await Promise.all(promises);

  const allAffPk = councilData.map(item => item.aff_pk);
  const allOfficerDetailByAffPK = await getAllOfficersDetail(allAffPk);

  dispatch ({
    type: RECEIVE_OFFICE_SUB_RECORD,
    payload: officeSubInfo,
  })
  dispatch({
    type: RECEIVE_ALL_OFFICER_BY_AFF_PK,
    payload: allOfficerDetailByAffPK
  })
};

const getSubLocal = async (aff_pk) => {
  try {
    const response = await getTreeHierarchy({
      data: { affiliate: aff_pk },
    });
    return response.data.sort((a, b) => {
      if (a.type > b.type) return 1;
      if (a.type < b.type) return -1;
      if (a.chapter > b.chapter) return 1;
      if (a.chapter < b.chapter) return -1;
      return 0;
    });
  } catch (error) {
    console.log(
      error?.message ? error.message : `Data Fetch Error ${error.status}`
    );
  }
  return [];
};

export const editOfficerDetail = ({ data }) => async (dispatch) => {
  await api(EDIT_OFFICER, 'post', data);
  dispatch({
    type: REFETCH_DATA
  })
};

export const editOfficeDetail = ({ data }) => async (dispatch) => {
  await api(EDIT_OFFICE, 'post', data);
  dispatch({
    type: REFETCH_DATA
  })
};