import { 
	RECEIVE_USER_PENDING_REQUEST
} from "redux/actions/userRequestActions";

const initialState = {
	userPendingRequests: null
};

export const userRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_USER_PENDING_REQUEST: {
			return {
				...state,
				userPendingRequests: action.payload
			}
		};
		default:
			return state;
	}
}