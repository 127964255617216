import { LoadingIcon } from "assets/icons/Icons";
import React from "react";

export const Button = ({ text, color, disabled, width }) => {
  return (
    <button
      className={`${
        !disabled ? color : "bg-slate-400"
      } py-1 sm:py-1.5 px-4 text-white rounded-md sm:text-[13px] min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate text-xs h-8 ${width}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export const SmallButton = ({ text, color, disabled, width }) => {
  return (
    <button
      className={`${
        !disabled ? color : "bg-slate-400"
      } py-1 px-2 min-w-[50px] text-white rounded-md hover:opacity-80 shadow-md duration-500 truncate text-xs h-6 ${width}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export const ButtonActionTable = ({ text, color, disabled }) => {
  return (
    <button
      className={`${color} py-1 sm:py-1.5 px-4 text-white rounded-md text-sm min-w-[50px] hover:opacity-80 shadow-lg duration-500 truncate`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export const DisableBtn = ({ text, width }) => {
  return (
    <button
      className={`bg-slate-400 py-1 sm:py-1.5 px-4 text-white rounded-md sm:text-[13px] min-w-[90px] shadow-lg cursor-default truncate text-xs h-8 ${width}`}
    >
      {text}
    </button>
  );
};

export const GreenButton = ({ text }) => {
  return (
    <button className="bg-green-10 mb-2 text-white w-[150px] py-1  rounded-md font-medium hover:opacity-80 duration-500">
      {text}
    </button>
  );
};

export const DisableButton = ({ text }) => {
  return (
    <button
      type="button"
      className="bg-green-10/40 mb-2 text-white w-[150px] py-1  rounded-md font-medium flex items-center justify-center"
      disabled
    >
      <p className="mr-2"> {text}</p>
      <LoadingIcon />
    </button>
  );
};

export const ClearButton = () => {
  return (
    <div className="bg-red-500 mb-2 text-white px-4 py-1 rounded-md font-medium hover:opacity-80 cursor-pointer">
      Clear
    </div>
  );
};
