import { CloseIcon } from "assets/icons/Icons";
import React from "react";

export const ENUModal = ({ 
  children, 
  title,
  open, 
  setOpen 
}) => {
  return (
    open && <div className="fixed z-50 inset-0 bg-slate-900/30 w-full h-screen flex justify-center items-center p-2">
      <div className="bg-white m-4 rounded-md shadow-xl w-full sm:w-fit sm:max-w-3xl">
        <div className="flex justify-center">
          <div className="text-2xl font-medium text-center py-3 pl-8 flex-1">
            <div>{title}</div>
          </div>
          <div
            className="cursor-pointer text-slate-700 hover:opacity-80 p-2 mt-1"
            onClick={() => setOpen(false)}
          >
            <CloseIcon size="24" />
          </div>
        </div>
        <div className="max-h-[85vh] overflow-auto">{children}</div>
      </div>
    </div>
  );
}

