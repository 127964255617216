import chunk from "lodash.chunk";
import { useDispatch, useSelector } from "react-redux";

import { getOfficerRecord } from "services/officer";
import { ObjectSchema } from "yup";
import { getAff_pk } from "services/officer";

export const getDataCouncil = (officeInfo) => {
	let councilData = [];
	officeInfo.forEach((item) => {
		if (!item?.aff_pk) {
			item.sub_affiliates.forEach((item) => councilData.push(item));
		} else {
			councilData.push(item);
		}
	});

	return (
		councilData.sort((a, b) => {
			if (a.type > b.type) return 1;
			if (a.type < b.type) return -1;
			if (a.chapter > b.chapter) return 1;
			if (a.chapter < b.chapter) return -1;
			return 0;
		}))
}

export const getAllOfficersDetail = async (allAffPK) => {
	const groups = chunk(allAffPK, 5);

	const promises = groups.map(async (element) => {
		const { data } = await getOfficerRecord({
			data: { aff_pks: element },
		});
		return data;
	});
	const result = await Promise.all(promises);
	return result.flat();
}

export const getOfficerDetailsBySelectedAffPks = async (
	allOfficerDetails,
	selectedAffPks
) => {
	
	const existingOfficerDetails = allOfficerDetails.filter(item =>
		selectedAffPks.includes(item?.aff_info?.aff_pk))
	const newAffPks = selectedAffPks.filter(affPk => 
		!existingOfficerDetails.some(item => item?.aff_info?.aff_pk === affPk));
	
	if ( newAffPks.length === 0 ) return existingOfficerDetails;

	const newOfficerDetails = await getAllOfficersDetail(newAffPks);

	return [...existingOfficerDetails, ...newOfficerDetails];
	
}

export const getOffices = async (
	allOfficerDetails,
	affPk
) => {
	const response = await getOfficerDetailsBySelectedAffPks(
		allOfficerDetails,
		[affPk]
	);

	return response && (response[0]?.aff_info?.offices || [] );
}

export const getSelectedOfficers = async (
	allOfficerDetails,
	selectedOffice
) => {
	try {
		const response = await getOfficerDetailsBySelectedAffPks(
			allOfficerDetails,
			[selectedOffice.aff_pk]
		);
		if (!response) return [];
		
		const officeInfo = response[0];
		let currentOfficers = [];

		officeInfo?.aff_info?.offices.forEach((element) => {
			if (element.officers.length > 0) {
				element.officers.forEach((officer) => {
					currentOfficers.push({
						...{ ...element, officers: [] },
						...officer,
						...officer.office,
						chapter: officeInfo.aff_info.chapter,
						council: officeInfo.aff_info.council,
						local: officeInfo.aff_info.local,
						sublocal: officeInfo.aff_info.sublocal,
						type: officeInfo.aff_info.type,
						state: selectedOffice.state,
					});
				});
			}
		});
		return currentOfficers;

  } catch (err) {
    console.error("Error", err);
    return;
  }
}

export const getAffPkFromAffInfo = async (
	allOfficerDetails,
	affInfo
) => {
	const matchedOffices =  allOfficerDetails.filter(item => (
		item.aff_info.type === affInfo.type &&
		item.aff_info.council === affInfo.council &&
		(!item.aff_info.local || (item.aff_info.local || 0) === affInfo.local) &&
		(item.aff_info.sublocal || 0) === affInfo.sublocal &&
		item.aff_info.state === affInfo.state
	))
	if (matchedOffices.length > 0)
		return matchedOffices[0].aff_info.aff_pk;
	else {
		const affData = await getAff_pk({data: [affInfo]});
		if (affData.data && affData.data[0].aff_pk) {
			return affData.data[0].aff_pk;
		}
	}

	return null;
}

export const convertStandardAddress = (address) => {
	let newAddress = {}
	Object.keys(address).forEach(key => {
		newAddress[key.toLowerCase()] = address[key]
	});
	return newAddress;
}