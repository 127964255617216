import React from 'react';
import styled from 'styled-components'

export const SPACING_SCALE = {
	smallest: 2,
	small: 4,
	regular: 8,
	large: 12,
	larger: 16,
	huge: 24,
	massive: 32,
};

export const ENUFlexSpace = ({
	flex,
	style,
	children
}) => (
	<div
		style={{
			...style,
			flex: flex || 1,
		}}
	>
		{children}
	</div>
);

export const ENUSpaceSmallest = styled.div`
  padding: ${SPACING_SCALE.smallest}px;
`;

export const ENUSpaceSmall = styled.div`
  padding: ${SPACING_SCALE.small}px;
`;

export const ENUSpace = styled.div`
  padding: ${SPACING_SCALE.regular}px;
`;

export const ENUSpaceLarge = styled.div`
  padding: ${SPACING_SCALE.large}px;
`;

export const ENUSpaceLarger = styled.div`
  padding: ${SPACING_SCALE.larger}px;
`;

export const ENUSpaceHuge = styled.div`
  padding: ${SPACING_SCALE.huge}px;
`;

export const ENUSpaceMassive = styled.div`
  padding: ${SPACING_SCALE.massive}px;
`;