
import React from "react";

export const ENUEnvStage = ({ 
	text,
}) => {
	return (
		<div 
			style={{
				position: 'fixed',
				right: 12,
				bottom: 12,
				fontSize: 14,
				color: 'black',
				opacity: 0.4,
				pointerEvents: 'none',
				zIndex: 1000
			}}
		>
            {text}
        </div>
	);
};