import { 
  ENUButton
} from "components/ingredients";
import { 
  ENUOfficeHeader
} from "components/recipes";
import { ENUOfficeFeed } from "components/templates/Office/ENUOfficeFeed";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Tab from "pages/user/components/Tab";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import aff_pkActions from "redux/actions/aff_pk";
import { exportToExcel, formatDate, notifyError } from "utils/helper";
import { getOffices, getSelectedOfficers } from "utils/office";
import InfoSubLocal from "./InfoSubLocal";
import ModalOfficer from "./ModalOfficer";
import Pill from "./Pill";
import ModalAddOfficerTitle from "./ModalAddOfficerTitle";
import ModalEditOfficerTitle from "./ModalEditOfficerTitle";
import { ENULink } from "components/ingredients";

function InfoCouncil({
  data,
  subLocal,
  dataSubSelect,
  setDataSubSelect,
  dataLocalSelect,
  setDataLocalSelect,
}) {
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const authInfo = useSelector((state) => state.auth);
  const { refetchData } = useSelector((state) => state.officer);
  const {
    allOfficerDetailByAffPK 
  } = useSelector((state) => state.officer);

  const [isActive, setIsActive] = useState(true);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [dataOfficers, setDataOfficers] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const [isOpenAddModalTitle, setIsOpenAddModalTitle] = useState(false);
  const openAddModalTitle = () => setIsOpenAddModalTitle(true);
  const closeAddModalTitle = () => setIsOpenAddModalTitle(false);

  const [dataEditOfficerTitle, setDataEditOfficerTitle] = useState(null);
  const [isOpenEditModalTitle, setIsOpenEditModalTitle] = useState(false);
  const openEditModalTitle = () => setIsOpenEditModalTitle(true);
  const closeEditModalTitle = () => setIsOpenEditModalTitle(false);

  const [dataModal, setDataModal] = useState([]);
  const [uuid, setUuid] = useState("");

  const showModal = (data, uuid) => {
    setDataModal(data);
    setUuid(uuid);
    openModal();
  };

  const handleShowDetail = async () => {
    setShow(!show);
  };

  const exportExcel = async () => {
    setLoading(true);
    try {
      const currentDate = dayjs(new Date()).format("MM-DD-YYYY");
      const fileName = `${data?.short_name}-officers-${currentDate}`;
      const officers = await getSelectedOfficers(
        allOfficerDetailByAffPK,
        data
      );
      await exportToExcel({ officers, authInfo, fileName });
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const getDataOffice = async (affPk) => {
    setLoading(true);
    try {
      const offices = await getOffices(allOfficerDetailByAffPK, affPk);

      const today = new Date();
      const priorYears = new Date(
        new Date().setFullYear(today.getFullYear() - 3)
      );

      const filterPastOfficer = offices.map((item) => {
        const pastOfficers = item.past_officers.filter((pastOfficer) => {
          const expriedDate = new Date(pastOfficer.pos_expiration_dt * 1000);
          return expriedDate > priorYears;
        });
        return { ...item, past_officers: pastOfficers };
      });

      const filterActiveOffice = filterPastOfficer.filter((item) => {
        return item.is_active_office;
      });

      setDataOfficers(filterActiveOffice);
      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error: ", error);
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    dispatch(aff_pkActions.clearAff_pk());
    dispatch(aff_pkActions.addAff_pk(data.aff_pk));

    dispatch(aff_pkActions.clearShortName());
    dispatch(aff_pkActions.setShortName(data.short_name));
    navigate(`/user/edit`);
  };

  const handleChange = (event, item) => {
    let removeLocal = dataLocalSelect;
    let removeSub = dataSubSelect;

    if (
      !event.target.checked &&
      dataLocalSelect.some((sub) => sub.aff_pk === data.aff_pk)
    ) {
      setDataLocalSelect(
        dataLocalSelect.filter((sub) => sub.aff_pk !== data.aff_pk)
      );
      dispatch(aff_pkActions.removeAff_pk(data.aff_pk));

      dispatch(aff_pkActions.removeShortName(data.short_name));

      item.sub_affiliates.forEach((element) => {
        dispatch(aff_pkActions.removeAff_pk(element.aff_pk));
        dispatch(aff_pkActions.removeShortName(element.short_name));
      });
      item.sub_affiliates.forEach((sub) => {
        removeSub = removeSub.filter((item) => item.aff_pk !== sub.aff_pk);
        removeLocal = removeLocal.filter((item) => item.aff_pk !== sub.aff_pk);
      });
      removeLocal = removeLocal.filter((e) => e.aff_pk !== item.aff_pk);

      setDataSubSelect(removeSub);
      setDataLocalSelect(removeLocal);
    } else {
      setDataLocalSelect([...dataLocalSelect, item]);
      setDataSubSelect([...dataSubSelect, ...item.sub_affiliates]);
      dispatch(aff_pkActions.addAff_pk(data.aff_pk));

      dispatch(aff_pkActions.setShortName(data.short_name));

      item.sub_affiliates.forEach((element) => {
        dispatch(aff_pkActions.removeAff_pk(element.aff_pk));
        dispatch(aff_pkActions.removeShortName(element.short_name));
      });
      item.sub_affiliates.forEach((element) => {
        dispatch(aff_pkActions.addAff_pk(element.aff_pk));
        dispatch(aff_pkActions.setShortName(element.short_name));
      });
    }
  };

  const handleSelectAll = async (event) => {
    let remove = dataLocalSelect;

    if (event.target.checked) {
      subLocal.forEach((item) => {
        dispatch(aff_pkActions.removeAff_pk(item.aff_pk));
        dispatch(aff_pkActions.addAff_pk(item.aff_pk));

        dispatch(aff_pkActions.removeShortName(item.short_name));
        dispatch(aff_pkActions.setShortName(item.short_name));
      });

      subLocal.forEach((sub) => {
        remove = remove.filter((local) => local.aff_pk !== sub.aff_pk);
      });
      subLocal.forEach((sub) => {
        remove.push(sub);
      });

      setDataLocalSelect([...remove]);
      setDataSubSelect(subLocal);
    } else {
      subLocal.forEach((item) => {
        dispatch(aff_pkActions.removeAff_pk(item.aff_pk));
        dispatch(aff_pkActions.removeShortName(item.short_name));
      });

      subLocal.forEach((sub) => {
        remove = remove.filter((local) => local.aff_pk !== sub.aff_pk);
      });

      setDataSubSelect([]);
      setDataLocalSelect(remove);
    }
  };

  useEffect(() => {
    getDataOffice(data.aff_pk);
  }, [data.aff_pk, allOfficerDetailByAffPK, refetchData]);

  const isCheckAllSub = useCallback(() => {
    const checkLocal = subLocal.every((sub) =>
      dataLocalSelect.some((item) => item.aff_pk === sub.aff_pk)
    );

    const checkSub = subLocal.every((sub) =>
      dataSubSelect.some((item) => item.aff_pk === sub.aff_pk)
    );

    return checkLocal || checkSub;
  }, [dataLocalSelect, dataSubSelect, subLocal]);

  const isSelect = () => {
    return dataLocalSelect.some((item) => item.aff_pk === data.aff_pk);
  };

  const handleSetActive = () => {
    setIsActive(true);
  };

  const handleSetExpired = () => {
    setIsActive(false);
  };

  const handleEditOfficerTitle = (value) => {
    setDataEditOfficerTitle({ ...value, status: data?.status });
    openEditModalTitle();
  };

  return (
    <div className="sm:px-4 md:px-6">
      <Loading isVisible={loading} />
      <div
        className={`${
          show && "border-green-600"
        } w-full flex justify-between items-center mt-2 sm:mb-6 md:mb-8 border-[3px] p-2 sm:p-4 rounded-2xl hover:shadow-md duration-500 flex-wrap gap-2`}
      >
        <ENUOfficeHeader
          show={show}
          isActive={isActive}
          data={data}
          dataOfficers={dataOfficers}
          handleChange={handleChange}
          isSelect={isSelect()}
          handleEdit={handleEdit}
          exportExcel={exportExcel}
          handleShowDetail={handleShowDetail}
        />

        {show && (
          <div className="w-full border-t">
            <div>
              {/* officers  */}
              {dataOfficers?.length > 0 ? (
                <>
                  <div className="pl-4 flex justify-between pr-4 sm:pr-10">
                    <div className="pt-4">
                      <Tab
                        handleSetActive={handleSetActive}
                        handleSetExpired={handleSetExpired}
                      />
                    </div>
                    {authInfo.role === "system_admin" && (
                      <div
                        className="flex items-center"
                        onClick={openAddModalTitle}
                      >
                        <ENUButton
                          text="Create New Office"
                          color="bg-orange-500"
                        />
                      </div>
                    )}
                  </div>
                  <ENUOfficeFeed 
                    dataOfficers={dataOfficers}
                    isActive={isActive}
                    authInfo={authInfo}
                    showModal={showModal}
                    handleEditOfficerTitle={handleEditOfficerTitle}
                  />
                </>
              ) : (
                <></>
              )}

              {subLocal.length > 0 && (
                <div className="flex items-center ml-4 lg:ml-12 mb-2 space-x-2 font-bold">
                  <input
                    type="checkbox"
                    name=""
                    id="selectAllSub"
                    className="h-4 w-4"
                    onChange={handleSelectAll}
                    checked={isCheckAllSub()}
                  />
                  <label htmlFor="selectAllSub">Select all</label>
                </div>
              )}
              {/*sub local */}
              <div className="">
                {subLocal.map((item, index) => {
                  return (
                    <InfoSubLocal
                      key={index}
                      data={item}
                      dataSubSelect={dataSubSelect}
                      setDataSubSelect={setDataSubSelect}
                      dataLocalSelect={dataLocalSelect}
                      setDataLocalSelect={setDataLocalSelect}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      {isOpenModal && (
        <Modal title="Officer information" closeModal={closeModal}>
          <ModalOfficer
            data={dataModal}
            uuid={uuid}
            affInfo={data}
            isActive={isActive}
          />
        </Modal>
      )}

      {isOpenAddModalTitle && authInfo.role === "system_admin" && (
        <Modal
          title={
            <p>
              {data.short_name}{" "}
              <span className="text-sm">{`${data.name} (${data.status})`}</span>
            </p>
          }
          closeModal={closeAddModalTitle}
        >
          <ModalAddOfficerTitle
            data={data}
            closeModal={closeAddModalTitle}
            getDataOffice={() => getDataOffice(data.aff_pk)}
            aff_pk={data.aff_pk}
          />
        </Modal>
      )}

      {isOpenEditModalTitle && authInfo.role === "system_admin" && (
        <Modal
          title={
            <p>
              {data.short_name}{" "}
              <span className="text-sm">{`${data.name} (${data.status})`}</span>
            </p>
          }
          closeModal={closeEditModalTitle}
        >
          <ModalEditOfficerTitle
            data={dataEditOfficerTitle}
            closeModal={closeEditModalTitle}
            getDataOffice={() => getDataOffice(data.aff_pk)}
          />
        </Modal>
      )}
    </div>
  );
}

export default memo(InfoCouncil);
