import { Auth } from "aws-amplify";
import { Button } from "components/Button";
import React from "react";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "utils/helper";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaChangePassword } from "utils/schema";

function ChangePassword({ setLoading }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaChangePassword),
  });

  const resetForm = () => {
    setValue("oldPassword", "");
    setValue("newPassword", "");
    setValue("confirmPassword", "");
  };

  const onSubmit = async ({ oldPassword, newPassword, confirmPassword }) => {
    if (newPassword === confirmPassword) {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        notifySuccess("Your password is updated successfully!");
        resetForm();
        setLoading(false);
      } catch (error) {
        notifyError(
          error?.message ? error.message : `Data Fetch Error ${error.status}`
        );
        setLoading(false);
      }
    } else {
      notifyError(
        "Password and Confirm Password do not match. Please try again!"
      );
    }
  };

  return (
    <div className="max-w-md">
      <p className="text-green-10 mb-3 border-l-2 border-green-10 pl-2">
        Change password
      </p>
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        {/* old password */}
        <div className="p-4 border rounded-lg shadow-md text-gray-500">
          <div className="sm:flex pb-5 items-center">
            <p className="text-sm mb-1 sm:min-w-[150px]">
              <span className="text-red-500">* </span>Old password:
            </p>
            <div className="relative w-full">
              <input
                type="password"
                className="border w-full h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
                {...register("oldPassword")}
              />
              {errors.oldPassword && (
                <p className="text-[11px] text-red-500 absolute right-0">
                  Please enter the required field
                </p>
              )}
            </div>
          </div>
          {/* new password */}
          <div className="sm:flex pb-5 items-center">
            <p className="text-sm mb-1 sm:min-w-[150px]">
              <span className="text-red-500">* </span>New password:
            </p>
            <div className="relative w-full">
              <input
                type="password"
                className="border w-full h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
                {...register("newPassword")}
              />
              {errors.newPassword && (
                <p className="text-[11px] text-red-500 absolute right-0">
                  Please enter the required field
                </p>
              )}
            </div>
          </div>
          {/* confirm password */}
          <div className="sm:flex pb-5 items-center">
            <p className="text-sm mb-1 sm:min-w-[150px]">
              <span className="text-red-500">* </span>
              Confirm password:
            </p>
            <div className="relative w-full">
              <input
                type="password"
                className="border w-full h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
                {...register("confirmPassword")}
              />
              {errors.confirmPassword && (
                <p className="text-[11px] text-red-500 absolute right-0">
                  Please enter the required field
                </p>
              )}
            </div>
          </div>
          {/* Save button */}
          <div className="font-san flex justify-end">
            <Button text="Save" color="bg-blue-500" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
