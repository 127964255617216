import React from "react";
import { memo } from "react";
import { formatDate } from "utils/helper";

function ModalOfficer({ data, uuid, affInfo, isActive }) {
  const officerInfo = data[`${isActive ? "officers" : "past_officers"}`].find(
    (el) => el.uuid === uuid
  );
  return (
    <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Affiliate Identifier:</p>
        <p className="font-[500] w-1/2">{`${affInfo?.short_name}`}</p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Title:</p>
        <p className="font-[500] w-1/2">{data?.aff_title}</p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Name:</p>
        <p className="font-[500] w-1/2">{`${officerInfo?.first_nm} ${officerInfo?.last_nm}`}</p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Member ID:</p>
        <p className="font-[500] w-1/2">{officerInfo?.person_pk}</p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Start:</p>
        <p className="font-[500] w-1/2">
          {!officerInfo?.pos_start_dt || officerInfo?.pos_start_dt === 0 ? (
            <span>&mdash;</span>
          ) : (
            formatDate(officerInfo?.pos_start_dt * 1000)
          )}
        </p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">End:</p>
        <p className="font-[500] w-1/2">
          {!officerInfo?.pos_end_dt || officerInfo?.pos_end_dt === 0 ? (
            <span>&mdash;</span>
          ) : (
            formatDate(officerInfo?.pos_end_dt * 1000)
          )}
        </p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Expiration:</p>
        <p className="font-[500] w-1/2">
          {!officerInfo?.pos_expiration_dt ||
          officerInfo?.pos_expiration_dt === 0 ? (
            <span>&mdash;</span>
          ) : (
            formatDate(officerInfo?.pos_expiration_dt * 1000)
          )}
        </p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Suspended:</p>
        <p className="font-[500] w-1/2">
          {!officerInfo?.suspended_dt || officerInfo?.suspended_dt === 0 ? (
            <span>&mdash;</span>
          ) : (
            formatDate(officerInfo?.suspended_dt * 1000)
          )}
        </p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Month of Election:</p>
        <p className="font-[500] w-1/2">{data?.month_of_election.desc}</p>
      </div>

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Length of Term:</p>
        <p className="font-[500] w-1/2">
          {officerInfo?.office?.length_of_term}
        </p>
      </div>

      {/* <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Temporary Reporting Officer:</p>
        <p className="font-[500] w-1/2">
          {officerInfo?.reporting_officer_fg ? <CheckIcon /> : <SquareIcon />}
        </p>
      </div> */}

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Address: </p>
        <p className="font-[500] w-1/2">
          {officerInfo?.address.addr1}
          {officerInfo?.address.addr1 && officerInfo?.address.addr2 && ", "}
          {officerInfo?.address.addr2}
          {officerInfo?.address.city && officerInfo?.address.addr2 && ", "}
          {officerInfo?.address.city}
          {officerInfo?.address.city && officerInfo?.address.state && ", "}
          {officerInfo?.address.state}
          {officerInfo?.address.zipcode && officerInfo?.address.state && ", "}
          {officerInfo?.address.zipcode}
        </p>
      </div>

      {/* Officer Mailing address Flag */}
      {/* <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Officer:</p>
        <p className="font-[500] w-1/2"></p>
      </div> */}

      <div className="flex pb-3">
        <p className="min-w-[150px] w-1/2">Phone:</p>
        <p className="font-[500] w-1/2">
          {officerInfo?.phone || <span>&mdash;</span>}
        </p>
      </div>
      {officerInfo?.last_modified_date && (
        <div className="flex">
          <span className="text-sm italic flex-1 text-right">
            Last modified by <b>{officerInfo?.last_modified_by || "system"}</b>{" "}
            on {formatDate(officerInfo?.last_modified_date * 1000)}
          </span>
        </div>
      )}
    </div>
  );
}

export default memo(ModalOfficer);
