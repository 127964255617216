import React from "react";

import { ENUButton } from "./ENUButton";
import { ENUDisableBtn } from "./ENUDiableBtn";

export const ENUTransmittalButton = ({
    text,
    color,
    width,
    disabled,
    onClick
}) => {
    return (
        <div
            className="mt-2 lg:mt-0 lg:mr-2"
            onClick={disabled? null : onClick}
        >
            {disabled?
                <ENUDisableBtn
                    text={text}
                    width={width}
                />
            :    <ENUButton
                    text={text}
                    color={color}
                    width={width}
                />
            }
        </div>
    )
}