import { CloseIcon } from "assets/icons/Icons";
import React from "react";

export default function SelectItem({ label }) {
  return (
    <div className="flex justify-center items-center mr-1 mb-1 bg-cyan-500 h-8 flex-wrap rounded-lg text-white">
      <p className="px-2 text-sm">{label}</p>
      <span className="pr-1 hover:opacity-80 cursor-pointer">
        <CloseIcon size="14" />
      </span>
    </div>
  );
}
