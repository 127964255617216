import React, { useState } from "react";

function Tab({ handleSetActive, handleSetExpired }) {
  const [isActive, setIsActive] = useState(true);

  const handleActive = () => {
    if (!isActive) {
      setIsActive(true);
      handleSetActive();
    }
  };

  const handleExpired = () => {
    if (isActive) {
      setIsActive(false);
      handleSetExpired();
    }
  };

  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-gray-200">
      <li className="cursor-pointer" onClick={handleActive}>
        <p
          className={`inline-block py-2 px-2 rounded-t-lg ${
            isActive
              ? "text-blue-600 bg-gray-200"
              : "hover:text-gray-600 hover:bg-gray-100"
          }`}
        >
          Active
        </p>
      </li>
      <li className="cursor-pointer" onClick={handleExpired}>
        <p
          className={`inline-block py-2 px-2 rounded-t-lg ${
            !isActive
              ? "text-blue-600 bg-gray-200"
              : "hover:text-gray-600 hover:bg-gray-100"
          }`}
        >
          Previous Office Holders
        </p>
      </li>
    </ul>
  );
}

export default Tab;
