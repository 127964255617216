export const loginActionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
};
export const roleActionType = {
  CHANGE_ROLES: "CHANGE_ROLES",
};

export const editAff_pkTypes = {
  ADD_AFF_PK: "ADD_AFF_PK",
  REMOVE_AFF_PK: "REMOVE_ADD_AFF_PK",
  CLEAR_AFF_PK: "CLEAR_AFF_PK",
  SET_SHORT_NAME: "SET_SHORT_NAME",
  REMOVE_SHORT_NAME: "REMOVE_SHORT_NAME",
  CLEAR_SHORT_NAME: "CLEAR_SHORT_NAME",
};

export const logoutActionType = {
  LOGOUT: "LOGOUT",
};

export const SUCCESS_MESSAGE =
  "Thank you. Your user account request has been submitted to AFSCME for review";
export const ERROR_MESSAGE =
  "We have experienced an unexpected error during the transmission of your registration. We have been notified of this error and our actively trying to rectify this issue. Please try again to register";
export const VALIDATE_ERROR_MESSAGE =
  "You should select at least one affiliate";

export const requestMessage = {
  APPROVE_SUCCESS_MESSAGE: "Successfully Approved",
  APPROVE_ERROR_MESSAGE: "Approve failed",
  DENY_SUCCESS_MESSAGE: "Successfully Denied",
  DENY_ERROR_MESSAGE: "Deny failed",
  INFORMATION_MESSAGE: "No pending requests",
};

export const REQUIRED_FIELD = "Please enter the required field";
export const REQUIRED_EMAIL = "Email must be a valid email";
export const REQUIRED_ALPHABET = "Only alphabets";
export const REQUIRED_POSITIVE = "This field must be a positive number";
export const REQUIRED_INTEGER = "This field must be an integer number";

export const AFSCME_TITLE =[
  {
      "label": "International President",
      "value": {
          "code": 6001,
          "desc": "International President",
          "sort": 1,
          "international_title": true,
          "administratorship_title": false,
          "affiliate_title": false
      }
  },
  {
      "label": "International Secretary-Treasurer",
      "value": {
          "code": 6002,
          "desc": "International Secretary-Treasurer",
          "sort": 2,
          "international_title": true,
          "administratorship_title": false,
          "affiliate_title": false
      }
  },
  {
      "label": "International Vice-President",
      "value": {
          "code": 6003,
          "desc": "International Vice-President",
          "sort": 3,
          "international_title": true,
          "administratorship_title": false,
          "affiliate_title": false
      }
  },
  {
      "label": "Administrator",
      "value": {
          "code": 6004,
          "desc": "Administrator",
          "sort": 4,
          "international_title": false,
          "administratorship_title": true,
          "affiliate_title": true
      }
  },
  {
      "label": "Deputy Administrator",
      "value": {
          "code": 6005,
          "desc": "Deputy Administrator",
          "sort": 5,
          "international_title": false,
          "administratorship_title": true,
          "affiliate_title": true
      }
  },
  {
      "label": "Executive Director",
      "value": {
          "code": 6006,
          "desc": "Executive Director",
          "sort": 6,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Director",
      "value": {
          "code": 6007,
          "desc": "Director",
          "sort": 7,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Regional Director",
      "value": {
          "code": 6008,
          "desc": "Regional Director",
          "sort": 8,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "State President",
      "value": {
          "code": 6056,
          "desc": "State President",
          "sort": 8,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "State Director",
      "value": {
          "code": 6060,
          "desc": "State Director",
          "sort": 8,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "State Secretary Treasurer",
      "value": {
          "code": 6061,
          "desc": "State Secretary Treasurer",
          "sort": 8,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "President",
      "value": {
          "code": 6009,
          "desc": "President",
          "sort": 9,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Executive President",
      "value": {
          "code": 82466,
          "desc": "Executive President",
          "sort": 9,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Chairman",
      "value": {
          "code": 6010,
          "desc": "Chairman",
          "sort": 10,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Co-Chair",
      "value": {
          "code": 6011,
          "desc": "Co-Chair",
          "sort": 11,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Executive Vice-President",
      "value": {
          "code": 6012,
          "desc": "Executive Vice-President",
          "sort": 12,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Vice President",
      "value": {
          "code": 6013,
          "desc": "Vice President",
          "sort": 13,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Vice-Chairman",
      "value": {
          "code": 6014,
          "desc": "Vice-Chairman",
          "sort": 14,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "1st Vice President",
      "value": {
          "code": 6015,
          "desc": "1st Vice President",
          "sort": 15,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "2nd Vice President",
      "value": {
          "code": 6016,
          "desc": "2nd Vice President",
          "sort": 16,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "3rd Vice President",
      "value": {
          "code": 6017,
          "desc": "3rd Vice President",
          "sort": 17,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "4th Vice President",
      "value": {
          "code": 6018,
          "desc": "4th Vice President",
          "sort": 18,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "5th Vice President",
      "value": {
          "code": 6019,
          "desc": "5th Vice President",
          "sort": 19,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Conference Board Vice President",
      "value": {
          "code": 6020,
          "desc": "Conference Board Vice President",
          "sort": 20,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Regional Vice-President",
      "value": {
          "code": 6021,
          "desc": "Regional Vice-President",
          "sort": 21,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "District Vice-President",
      "value": {
          "code": 6022,
          "desc": "District Vice-President",
          "sort": 22,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Department Vice-President",
      "value": {
          "code": 6023,
          "desc": "Department Vice-President",
          "sort": 23,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Recording Secretary",
      "value": {
          "code": 6024,
          "desc": "Recording Secretary",
          "sort": 24,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Secretary",
      "value": {
          "code": 6025,
          "desc": "Secretary",
          "sort": 25,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Corresponding Secretary",
      "value": {
          "code": 6026,
          "desc": "Corresponding Secretary",
          "sort": 26,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Assistant Secretary",
      "value": {
          "code": 6027,
          "desc": "Assistant Secretary",
          "sort": 27,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Secretary Treasurer",
      "value": {
          "code": 6028,
          "desc": "Secretary Treasurer",
          "sort": 28,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Treasurer",
      "value": {
          "code": 6029,
          "desc": "Treasurer",
          "sort": 29,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Assistant Treasurer",
      "value": {
          "code": 6030,
          "desc": "Assistant Treasurer",
          "sort": 30,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Financial Secretary",
      "value": {
          "code": 6031,
          "desc": "Financial Secretary",
          "sort": 31,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Financial Reporting Officer",
      "value": {
          "code": 6032,
          "desc": "Financial Reporting Officer",
          "sort": 32,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Executive Board Member",
      "value": {
          "code": 6033,
          "desc": "Executive Board Member",
          "sort": 33,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Board of Directors Member",
      "value": {
          "code": 6034,
          "desc": "Board of Directors Member",
          "sort": 34,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "District Board Member",
      "value": {
          "code": 6035,
          "desc": "District Board Member",
          "sort": 35,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Regional Board Member",
      "value": {
          "code": 6036,
          "desc": "Regional Board Member",
          "sort": 36,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Department Board Member",
      "value": {
          "code": 6037,
          "desc": "Department Board Member",
          "sort": 37,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Conference Board Member",
      "value": {
          "code": 6038,
          "desc": "Conference Board Member",
          "sort": 38,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Chief Steward",
      "value": {
          "code": 6039,
          "desc": "Chief Steward",
          "sort": 39,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Sergeant-At-Arms",
      "value": {
          "code": 6040,
          "desc": "Sergeant-At-Arms",
          "sort": 40,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Trustee",
      "value": {
          "code": 6041,
          "desc": "Trustee",
          "sort": 41,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Past President",
      "value": {
          "code": 6042,
          "desc": "Past President",
          "sort": 42,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": false
      }
  },
  {
      "label": "Business Agent",
      "value": {
          "code": 6043,
          "desc": "Business Agent",
          "sort": 43,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Business Representative",
      "value": {
          "code": 6044,
          "desc": "Business Representative",
          "sort": 44,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Parliamentarian",
      "value": {
          "code": 6045,
          "desc": "Parliamentarian",
          "sort": 45,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Membership Secretary",
      "value": {
          "code": 82574,
          "desc": "Membership Secretary",
          "sort": 45,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Steward",
      "value": {
          "code": 6046,
          "desc": "Steward",
          "sort": 46,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Chapter Chairperson",
      "value": {
          "code": 6047,
          "desc": "Chapter Chairperson",
          "sort": 47,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Chapter Secretary",
      "value": {
          "code": 6048,
          "desc": "Chapter Secretary",
          "sort": 48,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Chapter Treasurer",
      "value": {
          "code": 6049,
          "desc": "Chapter Treasurer",
          "sort": 49,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Business Manager",
      "value": {
          "code": 6050,
          "desc": "Business Manager",
          "sort": 50,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Judicial Panel Chairperson",
      "value": {
          "code": 6051,
          "desc": "Judicial Panel Chairperson",
          "sort": 51,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Judicial Panel Member",
      "value": {
          "code": 6052,
          "desc": "Judicial Panel Member",
          "sort": 52,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Division Vice-President",
      "value": {
          "code": 6053,
          "desc": "Division Vice-President",
          "sort": 53,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Executive Secretary",
      "value": {
          "code": 6054,
          "desc": "Executive Secretary",
          "sort": 54,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Executive Treasurer",
      "value": {
          "code": 6055,
          "desc": "Executive Treasurer",
          "sort": 55,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Committee Chair",
      "value": {
          "code": 6057,
          "desc": "Committee Chair",
          "sort": 57,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Guardian",
      "value": {
          "code": 6058,
          "desc": "Guardian",
          "sort": 58,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Grievance Chair",
      "value": {
          "code": 6059,
          "desc": "Grievance Chair",
          "sort": 59,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Council Delegate",
      "value": {
          "code": 6062,
          "desc": "Council Delegate",
          "sort": 62,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Executive Board Member-At-Large",
      "value": {
          "code": 6063,
          "desc": "Executive Board Member-At-Large",
          "sort": 63,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Delegate",
      "value": {
          "code": 6064,
          "desc": "Delegate",
          "sort": 64,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Reporting Officer",
      "value": {
          "code": 6065,
          "desc": "Reporting Officer",
          "sort": 65,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Immediate Past President",
      "value": {
          "code": 6066,
          "desc": "Immediate Past President",
          "sort": 66,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": false
      }
  },
  {
      "label": "Unit Vice President",
      "value": {
          "code": 82777,
          "desc": "Unit Vice President",
          "sort": 68,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": true
      }
  },
  {
      "label": "Data Migration Default for AFSCMETitle",
      "value": {
          "code": 6067,
          "desc": "Data Migration Default for AFSCMETitle",
          "sort": 99,
          "international_title": false,
          "administratorship_title": false,
          "affiliate_title": false
      }
  }
];

export const TERM_LENGTH = [
  {
    label: "1 year",
    value: { code: 63001, desc: "1 year", sort_key: 1, type_key: "TermLength" },
  },
  {
    label: "2 years",
    value: {
      code: 63002,
      desc: "2 years",
      sort_key: 2,
      type_key: "TermLength",
    },
  },
  {
    label: "3 years",
    value: {
      code: 63003,
      desc: "3 years",
      sort_key: 3,
      type_key: "TermLength",
    },
  },
  {
    label: "4 years",
    value: {
      code: 63004,
      desc: "4 years",
      sort_key: 4,
      type_key: "TermLength",
    },
  },
  {
    label: "Indefinite",
    value: {
      code: 63005,
      desc: "Indefinite",
      sort_key: 5,
      type_key: "TermLength",
    },
  },
  {
    label: "Temporary",
    value: {
      code: 63006,
      desc: "Temporary",
      sort_key: 6,
      type_key: "TermLength",
    },
  },
];

export const MONTH_OFFCR_ELECTION = [
  {
    label: "None",
    value: {
      code: 82139,
      desc: "None",
      sort_key: 17,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "January",
    value: {
      code: 78001,
      desc: "January",
      sort_key: 1,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "February",
    value: {
      code: 78002,
      desc: "February",
      sort_key: 2,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "March",
    value: {
      code: 78003,
      desc: "March",
      sort_key: 3,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "April",
    value: {
      code: 78004,
      desc: "April",
      sort_key: 4,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "May",
    value: {
      code: 78005,
      desc: "May",
      sort_key: 5,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "June",
    value: {
      code: 78006,
      desc: "June",
      sort_key: 6,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "July",
    value: {
      code: 78007,
      desc: "July",
      sort_key: 7,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "August",
    value: {
      code: 78008,
      desc: "August",
      sort_key: 8,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "September",
    value: {
      code: 78009,
      desc: "September",
      sort_key: 9,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "October",
    value: {
      code: 78010,
      desc: "October",
      sort_key: 10,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "November",
    value: {
      code: 78011,
      desc: "November",
      sort_key: 11,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "December",
    value: {
      code: 78012,
      desc: "December",
      sort_key: 12,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "1st Qtr.",
    value: {
      code: 78013,
      desc: "1st Qtr.",
      sort_key: 13,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "2nd Qtr.",
    value: {
      code: 78014,
      desc: "2nd Qtr.",
      sort_key: 14,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "3rd Qtr.",
    value: {
      code: 78015,
      desc: "3rd Qtr.",
      sort_key: 15,
      type_key: "MonthOffcrElection",
    },
  },
  {
    label: "4th Qtr.",
    value: {
      code: 78016,
      desc: "4th Qtr.",
      sort_key: 16,
      type_key: "MonthOffcrElection",
    },
  },
];
