import { ENULink } from "components/ingredients";
import {
  ENUOfficerDetail, 
  ENUOfficerShortInfoCell
} from "components/recipes";

export const ENUOfficeFullProfile = ({
  item,
  index,
  handleEditOfficerTitle,
  isActive,
  showModal,
  authInfo,
  isTitleHover,
  handleSelectOffice
}) => {
  if (!item) {
    return (
      <>No Matched Office</>
    );
  }
  return (
    <div key={index} className={`pb-2.5 ${isTitleHover? 'lg:w-1/2': 'lg'} font-bold`}>
      <div className="sm:flex sm:gap-2 sm:items-center mb-2">
        <div className="group relative">
          {!!isTitleHover &&
            <>
              <div className="hidden group-hover:block bg-green-500 mt-2 w-3 h-3 absolute bottom-5 left-2 rotate-45"></div>
            
              <div className="hidden absolute bottom-6 bg-green-500 text-white group-hover:block group-hover:duration-1000 w-52 p-1 rounded-lg">
                <p className="text-sm font-normal">
                  {item?.title}
                </p>
              </div>
            </>
          }     
          <span
            className="cursor-pointer hover:text-blue-600 duration-300"
            onClick={() => handleEditOfficerTitle(item)}
          >
            {item?.aff_title}{" "}
          </span>
        </div>
        {/* pill */}
        <ENUOfficerShortInfoCell
          key={index}
          item={item}
          isActive={isActive}
        >
          {authInfo && authInfo.role === "system_admin" && (
            <ENULink
              text="edit"
              textColor="text-blue-600"
              bgColor="bg-blue-500"
              handleClick={() =>
                handleEditOfficerTitle(item)
              }
            />
          )}
          {handleSelectOffice &&
            <ENULink
              text="SELECT"
              textColor="text-blue-600"
              bgColor="bg-blue-500"
              handleClick={() =>
                handleSelectOffice(item)
              }
            />
          }
        </ENUOfficerShortInfoCell>
      </div>

      <ENUOfficerDetail
        key={index}
        item={item}
        isActive={isActive}
        showModal={showModal}
      />

    </div>
  )
}