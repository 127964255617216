import api from "services/api";
import { AFFILIATE_STATE_LIST, REQUEST_USER_ACCESS } from "utils/request";

export const fetchStateAPI = () => {
  return api(AFFILIATE_STATE_LIST, 'post');
};

export const fetchAffliatessAPI = ({ data }) => {
  return api(AFFILIATE_STATE_LIST, 'post', data);
};

export const fetchSelectSublocalAPI = ({ data }) => {
  return api(AFFILIATE_STATE_LIST, 'post', data);
};

export const reigsterUserData = ({ data }) => {
  return api(REQUEST_USER_ACCESS, 'post', data);
};
