import React from "react";

export default function TextInput({ label, name, register, errors }) {
  return (
    <div className="flex items-center justify-end text-sm pb-5 md:mr-4">
      <p className="pr-2 md:text-sm text-[0.8rem]">
        <span className="text-red-500">*</span> {label}
      </p>
      <div className="w-[70%] md:w-3/4 relative">
        <input
          className="border w-full h-7 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
          {...register(name, {
            required: true,
            // pattern: /^([a-zA-Z][a-z]*[\s]{0,1}[a-z]+)+$/,
          })}
        />
        {errors[name] && (
          <p className="absolute right-0 text-xs text-red-500">
            {errors[name].message}
          </p>
        )}
      </div>
    </div>
  );
}

export function EmailInput({ label, name, register, errors }) {
  return (
    <div className="flex items-center justify-end text-sm pb-5 md:mr-4">
      <p className="pr-2 md:text-sm text-[0.8rem]">
        <span className="text-red-500">*</span> {label}
      </p>
      <div className="w-[70%] md:w-3/4 relative">
        <input
          className="border w-full h-7 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
          {...register(name, {
            required: true,
            // pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          })}
        />
        {errors[name] && (
          <p className="absolute right-0 text-xs text-red-500">
            {errors[name].message}
          </p>
        )}
      </div>
    </div>
  );
}

export function PhoneInput({ label, name, register, errors }) {
  return (
    <div className="flex items-center justify-end text-sm pb-5 relative md:mr-4">
      <p className="pr-2 md:text-sm text-[0.8rem]">
        <span className="text-red-500">*</span> {label}
      </p>
      <div className="w-[70%] md:w-3/4  relative group hover:text-gray-400">
        <input
          className="border w-full h-7 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
          {...register(name, {
            required: true,
          })}
        />
        {errors[name] && (
          <p className="absolute right-0 text-xs text-red-500">
            {errors[name].message}
          </p>
        )}
        <div className="hidden group-hover:block absolute rounded z-50 right-0 bg-slate-400 mt-1">
          <p className="text-white text-xs w-[13rem] md:text-sm md:w-[17rem] p-2">
            By providing my cell phone number I consent to receive calls
            (including recorded or autodialed calls, or texts) at that number
            from AFSCME and its affiliated labor, political and charitable
            organizations on any subject matter.  <br/> My carrier’s rates may apply. 
            I may modify my preferences by calling the Union at
            202-429-8400 , 1-855-237-2631 (toll-free) or emailing the Union at OfficerUpdates@afscme.org.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
