import { editAff_pkTypes } from "utils/constants";

const addAff_pk = (payload) => ({
  type: editAff_pkTypes.ADD_AFF_PK,
  payload,
});

const removeAff_pk = (payload) => ({
  type: editAff_pkTypes.REMOVE_AFF_PK,
  payload,
});

const clearAff_pk = (payload) => ({
  type: editAff_pkTypes.CLEAR_AFF_PK,
  payload,
});

const setShortName = (payload) => ({
  type: editAff_pkTypes.SET_SHORT_NAME,
  payload,
});

const removeShortName = (payload) => ({
  type: editAff_pkTypes.REMOVE_SHORT_NAME,
  payload,
});

const clearShortName = (payload) => ({
  type: editAff_pkTypes.CLEAR_SHORT_NAME,
  payload,
});

const aff_pkActions = {
  addAff_pk,
  removeAff_pk,
  clearAff_pk,
  setShortName,
  removeShortName,
  clearShortName,
};

export default aff_pkActions;
