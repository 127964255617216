import React from "react";
import Form from "./components/Form";

export default function Register() {
  return (
    <div className="relative bg-img h-screen min-h-[900px]">
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full bg-blue-600 opacity-60" />
      <Form />
    </div>
  );
}
