import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import "styles/login.css";
import Form from "./components/Form";

export default function Login() {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  if (isLoggedIn) {
    return <Navigate to="/user" state={{ from: location }} />;
  }

  return (
    <div className="relative bg-img h-screen min-h-[650px]">
      <div className="w-full h-screen min-h-[650px] bg-blue-600 opacity-60 absolute" />
      <Form />
    </div>
  );
}
