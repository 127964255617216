import { LoadingIcon } from "assets/icons/Icons";
import React from "react";

export const ENUDisableBtn = ({ text, width }) => {
    return (
      <button
        className={`bg-slate-400 py-1 sm:py-1.5 px-4 text-white rounded-md sm:text-[13px] min-w-[90px] shadow-lg cursor-default truncate text-xs h-8 ${width}`}
      >
        {text}
      </button>
    );
  };