import { LoadingIcon } from "assets/icons/Icons";
import React from "react";

export const ENUButton = ({
	text, 
	color, 
	disabled, 
	width 
}) => {
	return (
		<button
			className={`${!disabled ? color : "bg-slate-400"
				} py-1 sm:py-1.5 px-4 text-white rounded-md sm:text-[13px] min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate text-xs h-8 ${width}`}
			disabled={disabled}
		>
			{text}
		</button>
	);
};