import React from "react";
import { ENUModal } from "./ENUModal";
import { ENUOfficeFeed } from "components/templates";

export const ENUSelectOfficerModal = ({  
  title,
  open, 
  setOpen,
  officer,
  handleSelectOffice
}) => {

  return (
    <ENUModal
      open={open} 
      setOpen={setOpen}
      title={title}
    >
      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto min-w-500">
        <ENUOfficeFeed 
          // dataOfficers={officer?.matched_officers.map(item => item.office)}
          dataOfficers={officer?.matched_offices}
          isActive={true}
          showModal={() => {}}
          handleEditOfficerTitle={() => {}}
          isTitleHover={false}
          handleSelectOffice={handleSelectOffice}
        />
      </div>
    </ENUModal>
  );
}

