import { combineReducers } from "redux";
import { aff_pkReducer } from "./aff_pk";
import { authReducer } from "./auth";
import { officerReducer } from "./officerReducer";
import { userRequestReducer } from "./userRequestReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  aff_pk: aff_pkReducer,
  officer: officerReducer,
  userRequest: userRequestReducer
});

export default rootReducer;
