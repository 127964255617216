import { Button } from "components/Button";
import Loading from "components/Loading";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { schemaTransmittal } from "utils/schema";
import { exportToCSV, notifyError, notifySuccess } from "utils/helper";
import { officerTransmittal } from "services/officer";
import dayjs from "dayjs";

function ModalTransmittal({ closeModal }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schemaTransmittal) });

  const [loading, setLoading] = useState(false);

  const listAff_pk = useSelector((state) => state.aff_pk.listAff_pk);
  const listShort_name = useSelector((state) => state.aff_pk.listShort_name);

  const onSubmit = async (submitData) => {
    const startDate = dayjs(submitData.start_date, "YYYY-MM-DD").format(
      "MM-DD-YYYY"
    );
    const endDate = dayjs(submitData.end_date, "YYYY-MM-DD").format(
      "MM-DD-YYYY"
    );

    setLoading(true);
    let fileName = `${listShort_name[0]}-officer-transmittal-${startDate}-${endDate}`;

    if (listShort_name?.length > 1) {
      fileName = `various-officer-transmittal-${startDate}-${endDate}`;
    }
    try {
      const response = await officerTransmittal({
        data: {
          start_date: dayjs(submitData.start_date).unix(),
          end_date: dayjs(submitData.end_date).unix(),
          aff_pks: listAff_pk,
        },
      });
      console.log("RECVD TRANSMITTAL DATA", dayjs(submitData.start_date).unix(), dayjs(submitData.end_date).unix(), listAff_pk);
      console.log(response.data);
      exportToCSV(response.data, fileName);
      notifySuccess("Success");
      closeModal();
      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Loading isVisible={loading} />
      <input autoComplete="off" name="hidden" type="text" className="hidden" />

      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">
            <span className="text-red-500">*</span> Start date:
          </p>
          <div className="">
            <input
              type="date"
              className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
              {...register("start_date")}
            />
            {errors.pos_start_dt && (
              <p className="text-red-500 text-xs float-right">
                Please enter the required field
              </p>
            )}
          </div>
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">
            <span className="text-red-500">*</span> End date:
          </p>
          <div>
            <input
              type="date"
              className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
              {...register("end_date")}
            />
            {errors.pos_end_dt && (
              <p className="text-red-500 text-xs float-right">
                Please enter the required field
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-end space-x-8 mt-4">
          <div onClick={closeModal}>
            <Button text={"Cancel"} color={"bg-red-500"} />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 py-1 sm:py-1.5 px-4 text-white rounded-md text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500`}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default memo(ModalTransmittal);
