import { Button } from "components/Button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editOfficer, getAff_pk } from "services/officer";
import { 
  getOfficerDetailsBySelectedAffPks,
  getAffPkFromAffInfo
} from "utils/office";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading";
import { 
  notifyError, 
  formatDateISO, 
  diffYears 
} from "utils/helper";
import Select from "react-select";
import dayjs from "dayjs";
import { schemaEditExistOfficers } from "utils/schema";
import { ENUEditOfficerField, ENUEditOfficerDateField } from "components/recipes";
import { editOfficerDetail } from "redux/actions/OfficerActions";

const EditExistOfficerModal = ({
  dataEdit,
  closeModal,
  officers,
  setDataOfficer,
  handleLoadingFile,
}) => {
  console.log("🚀 ===== dataEdit", dataEdit);
  const dispatch = useDispatch();

  const { 
    allOfficerDetailByAffPK 
  } = useSelector((state) => state.officer);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaEditExistOfficers),
  });

  const [loading, setLoading] = useState(false);
  const [dataSelect, setDataSelect] = useState([]);
  const [officerSelect, setOfficerSelect] = useState(null);

  const [errorEndDate, setErrorEndDate] = useState(false);

  const onSubmit = async (submitData) => {
    const today = new Date();
    const submitDate = new Date(submitData.pos_end_dt);

    if (submitDate < today) {
      setLoading(true);
      try {
        await dispatch(editOfficerDetail({
          data: [
            {
              uuid: officerSelect.value.uuid,
              aff_pk: officerSelect.value.aff_pk,
              pos_end_dt: dayjs(submitData.pos_end_dt).unix(),
            },
          ],
        }));

        const updateErrors = officers.map((item) => {
          if (item.enterprise_ID === dataEdit.enterprise_ID) {
            const removeSeatError = item.errors.filter(
              (error) => error.message !== "No Available Seat"
            );
            return { ...item, errors: removeSeatError };
          }
          return item;
        });

        const dataTableUpdate = await handleLoadingFile(updateErrors, true);
        setDataOfficer(dataTableUpdate);

        closeModal();
      } catch (error) {
        console.log("🚀 ===== error", error);
      }
      setLoading(false);
    } else {
      setErrorEndDate(true);
    }
  };

  // get matched offices based on title and terms of length
  const getMatchedOffices = (offices, newOffice) => {
    let matchedOffices = offices.filter(
      (office) =>
        (office.aff_title && office.aff_title.toLowerCase() === newOffice.officer_title.toLowerCase()) ||
        (office.title && office.title.toLowerCase() === newOffice.officer_title.toLowerCase()) ||
        (office.afscme_title && office.afscme_title.toLowerCase() === newOffice.officer_title.toLowerCase())
    );
    if (matchedOffices.length > 1) {
      const termEstimate = diffYears(
        new Date(newOffice.expiration_date),
        new Date(newOffice.election_date)
      )
      if ( termEstimate in [1, 2, 3, 4] &&
        matchedOffices.some(office => parseInt(office.term.split(' ')[0]) === termEstimate)) 
      {
        matchedOffices = matchedOffices.filter(office => 
          parseInt(office.term.split(' ')[0]) === termEstimate
        )
      }
      console.log(`new terms: [${matchedOffices.map(o => o.term.split(' '[0])).join(', ')}]`);
    }
    return matchedOffices;
  }

  // get matched officers who is going to fill new position
  const getMatchedOfficers = (intialMatchedOfficers, newOfficers) => {
    const enterpriseIDs = newOfficers.map(elm => elm.enterprise_ID);
    if (intialMatchedOfficers.some(elm => !(enterpriseIDs.includes(elm.person_pk)))){
      return intialMatchedOfficers.filter(elm => !(enterpriseIDs.includes(elm.person_pk)));
    }
    return intialMatchedOfficers;
  }

  const getListEditOfficer = async () => {
    try {
      setLoading(true);

      const affPk = await getAffPkFromAffInfo(
        allOfficerDetailByAffPK,
        {
          state: dataEdit.state,
          type: dataEdit.aff_type,
          council: +dataEdit.council,
          local: +dataEdit.local,
          sublocal: +dataEdit.subunit,
        }
      )

      if (affPk) {
        // match all office
        // const affInfo = await getOfficerDetailsBySelectedAffPks(
        //   allOfficerDetailByAffPK,
        //   [affPk]
        // )
        // console.log("🚀 ===== affInfo", affInfo);

        // const listOffice = affInfo[0].aff_info.offices;
        // const matchedOffices = getMatchedOffices(listOffice, dataEdit);
        // console.log("🚀 ===== filterOffice", matchedOffices);

        // match selected office
        const matchedOffices = [dataEdit.preferred_office]
        const matchedOfficers = getMatchedOfficers(
          matchedOffices.reduce((prev, curr) => 
            prev.concat(curr.officers)
          , []),
          officers
        );

        const listOfficerEdit = matchedOfficers.map((officer) => {
          console.log("🚀 ===== officer", officer);
          return {
            label: `${officer.first_nm} ${officer.last_nm}`,
            value: officer,
          };
        });

        setDataSelect(listOfficerEdit);
      } else {
        notifyError("no matching affiliate found");
        closeModal();
      }
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  const handleSelectOfficer = (value) => {
    setValue("first_nm", value.value.first_nm);
    setValue("last_nm", value.value.last_nm);
    setValue("person_pk", value.value.person_pk);
    setValue("pos_start_dt", formatDateISO(value.value.pos_start_dt * 1000));
    setValue("pos_end_dt", formatDateISO(dataEdit.election_date));
    setOfficerSelect(value);
  };

  useEffect(() => {
    getListEditOfficer();
  }, [dataEdit]);

  useEffect(() => {
    if (dataSelect.length > 0) {
      handleSelectOfficer(dataSelect[0]);
    }
  }, [dataSelect])

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Loading isVisible={loading} />

      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
        <div className="mb-4">
          <Select
            value={officerSelect}
            options={dataSelect}
            onChange={handleSelectOfficer}
          />
        </div>
        <ENUEditOfficerField 
          register={register}
          title={'First Name'}
          registerId={'first_nm'}
        />
        <ENUEditOfficerField 
          register={register}
          title={'Last Name'}
          registerId={'last_nm'}
        />
        <ENUEditOfficerField 
          register={register}
          title={'Member ID'}
          registerId={'person_pk'}
        />
        <ENUEditOfficerDateField 
          register={register}
          title={'Start date'}
          registerId={'pos_start_dt'}
          errorEndDate={errorEndDate}
          errors={errors}
          disabled={true}
        />
        <ENUEditOfficerDateField 
          register={register}
          title={'End date'}
          registerId={'pos_end_dt'}
          errorEndDate={errorEndDate}
          errors={errors}
          disabled={false}
        />
        <div className="flex justify-end space-x-8 mt-4">
          <div onClick={closeModal}>
            <Button text={"Cancel"} color={"bg-red-500"} />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 py-1 sm:py-1.5 px-4 text-white rounded-md text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500`}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditExistOfficerModal;
