import {
  SignOutIcon,
  ListIcon,
  IconFile,
  SettingIcon,
} from "assets/icons/Icons";
import logo from "assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import authActions from "redux/actions/auth";

const SideBar = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state.auth);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(
      authActions.logout({
        isLoggedIn: false,
        role: null,
      })
    );
    navigate("/login");
  };

  return (
    <div>
      <aside className="h-full min-h-screen shadow-2xl w-64 flex flex-col items-center space-y-5 justify-start relative bg-white text-green-10">
        <div>
          <img className="w-auto h-24 mx-auto" src={logo} alt="logo" />
        </div>

        {/* List officers */}
        <Link
          to="/user"
          className="h-10 w-full flex items-center pl-8 cursor-pointer hover:text-white hover:bg-green-10 hover:shadow-icon  hover:duration-300 hover:ease-linear"
        >
          <div className="flex justify-start">
            <IconFile />
            <p className="text-xl ml-4">Officers</p>
          </div>
        </Link>

        {/* user-request */}
        {authInfo.role === "system_admin" && (
          <Link
            to="/user-request"
            className="h-10 w-full flex items-center pl-8 cursor-pointer hover:text-white hover:bg-green-10 hover:shadow-icon  hover:duration-300 hover:ease-linear"
          >
            <div className="flex justify-start">
              <ListIcon />
              <p className="text-xl ml-4">User Request</p>
            </div>
          </Link>
        )}
        {/* Setting */}
        <Link
          to="/settings"
          className="h-10 w-full flex items-center pl-8 cursor-pointer hover:text-white hover:bg-green-10 hover:shadow-icon  hover:duration-300 hover:ease-linear"
        >
          <div className="flex justify-start">
            <SettingIcon />
            <p className="text-xl ml-4">Settings</p>
          </div>
        </Link>

        {/* sign out */}
        <div
          key="signout"
          className="h-10 w-full flex items-center pl-8 cursor-pointer hover:text-white hover:bg-green-10 hover:shadow-icon  hover:duration-300 hover:ease-linear"
          onClick={handleLogout}
        >
          <div className="flex justify-start items-center">
            <SignOutIcon />
            <div className="text-xl ml-4">
              Sign out
              <div className="hidden md:block left-1 -bottom-6 text-grey-10 text-xs">
                Signed In As {authInfo.name ? authInfo.name : ""}
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};
export default SideBar;
