import { ENUButton } from "components/ingredients";
import { DownIcon, UpIcon } from "assets/icons/Icons";

export const ENUOfficeHeader = ({
	show,
	isActive,
	data,
	dataOfficers,
	handleChange,
	isSelect,
	handleEdit,
	exportExcel,
	handleShowDetail
}) => {
	return (
		<>
			<div className="flex items-center gap-4 pl-2">
				<input
					className="h-4 w-4"
					type="checkbox"
					onChange={(event) => handleChange(event, data)}
					checked={isSelect}
				/>
				<p className="sm:text-xl font-semibold">
					{`${data.short_name}`}{" "}
					<span className="text-sm font-normal">{data.name}</span>
				</p>
			</div>

      {show && !dataOfficers?.length && <div>No Officer Data</div>}

			<div className="flex gap-2 items-center justify-center px-2">
				<div onClick={handleEdit}>
					<ENUButton text="Edit" color="bg-blue-500" disabled={!isActive} />
				</div>

				{/* export to excel */}
				<div onClick={exportExcel}>
					<ENUButton text="Export" color="bg-green-500" disabled={!isActive} />
				</div>

				<button className="text-gray-700" onClick={handleShowDetail}>
					{!show ? <DownIcon /> : <UpIcon />}
				</button>
			</div>
		</>
	)
}