import axios from "axios";
import { Auth } from "aws-amplify";
import logger from './logger';

// axios instance for making requests
const baseApi = axios.create({
  baseURL: process.env.REACT_APP_BACK_SERVER,
  timeout: 30000,
});
let requestConfig = null;
// request interceptor for adding token
baseApi.interceptors.request.use(
  async (config) => {
    // add token to request headers
    requestConfig = { ...config };
    try {
      config.headers.Authorization = (
        await Auth.currentSession()
      ).idToken.jwtToken;
    } catch (e) {
      await Auth.signOut();
      console.log("error:", e);
    }
    return config;
  },
  error => {
    logger.error(error);

    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    logger.error({
      error: error.message,
      url: requestConfig.url,
      data: requestConfig.data,
    });
    return Promise.reject(error);
  }
);

const api = async (suburl, method = 'get', data = null, contentType = 'application/json') => {
  if (!['get', 'put', 'post', 'delete'].includes(method)) {
    throw new Error('not implemented');
  }

  const resData = await baseApi({
    method,
    url: suburl,
    data,
    headers: {
      'Content-Type': contentType,
    },
  });

  logger.info(method, suburl, resData);

  return resData;
};

export default api;
