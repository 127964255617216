export const ENUEditOfficerField = ({
  register,
  title,
  autoComplete,
  registerId,
  disabled=true
}) => {
  return (
    <div className="sm:flex pb-3">
      <p className="sm:min-w-[150px]">{title}:</p>
      <input
        className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
        disabled={disabled}
        autoComplete={autoComplete}
        {...register(registerId)}
      />
    </div>
  )
}