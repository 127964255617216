import "./App.css";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, Switch } from "react-router-dom";
import Login from "./pages/login";
import Register from "./pages/register";
import { Amplify } from "aws-amplify";
import { COGNITO_CONFIG } from 'config/amplify';
import ProtectedApp from "pages/ProtectedApp";
import Layout from "components/layout";
import UpdatePassword from "pages/update-password";
import UserRequest from "pages/userRequest";
import ErrorPage from "pages/404";
import "react-toastify/dist/ReactToastify.css";
import Users from "pages/user";
import EditOfficers from "pages/user/editOfficers";
import ForgotPassword from "pages/forgot-password";
import { useDispatch, useSelector } from "react-redux";
import ImportOfficer from "pages/user/importOfficer";
import Settings from "pages/settings";
import Loading from "components/Loading";
import { getTreeHierarchy, } from "services/officer";
import { fetchOfficeRecord, fetchOfficeSubLocalRecord } from "redux/actions/OfficerActions";
import { notifyError } from "utils/helper";
import { ENUEnvStage } from "components/ingredients";

Amplify.configure({
  Auth: COGNITO_CONFIG,
});

const envStage =  process.env.REACT_APP_STAGE === "development"
  ? "DEV"
  : "PROD";

const App = () => {
  const dispatch = useDispatch();

  const authInfo = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { officeInfo, refetchData } = useSelector((state) => state.officer);

  useEffect(async () => {
    const currentRole = authInfo?.roles?.filter(
      (role) => role.role === authInfo.role
    );
    if (currentRole) {
      setLoading(true);
      await dispatch(fetchOfficeRecord({ 
        affiliates: currentRole[0].affiliates 
      }));
      setLoading(false);
    }
  }, [authInfo, dispatch]);

  useEffect(async () => {
    if (officeInfo.length > 0) {
      setLoading(true);
      if (refetchData)
        await dispatch(fetchOfficeSubLocalRecord(officeInfo));
      setLoading(false);
    };
  }, [officeInfo, refetchData, dispatch])

  return (
    <>
      <Loading isVisible={loading} />
      <Routes>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/update_password" element={<UpdatePassword />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />

        {authInfo.role === "system_admin" && (
          <Route
            path="/user-request"
            element={
              <ProtectedApp>
                <Layout />
              </ProtectedApp>
            }
          >
            <Route
              path=""
              element={ <UserRequest /> }
            />
          </Route>
          
        )}

        <Route
          path="/user"
          element={
            <ProtectedApp>
              <Layout />
            </ProtectedApp>
          }
        >
          <Route
            path=""
            element={ <Users /> }
          />
          <Route
            path="edit"
            element={ <EditOfficers /> }
          />
          <Route
            path="import"
            element={ <ImportOfficer /> }
          />
        </Route>
        <Route
          path="/settings"
          element={
            <ProtectedApp>
              <Layout />
            </ProtectedApp>
          }
        >
          <Route
            path=""
            element={<Settings />}
          />
        </Route>
        
      </Routes>
      <ENUEnvStage 
        text={envStage}
      />
    </>
  );
}

export default App;
