/** @format */

import api from "services/api";
import {
  EDIT_OFFICER,
  LIST_ADD_OFFICER,
  OFFICER_RECORD,
  TREE_HIERARCHY,
  OFFICER_TRANSMITTAL,
  FIND_AFF_PK,
} from "utils/request";
import { notifyError } from "utils/helper";
import { convertStandardAddress } from "utils/office";

export const getOfficerRecord = async ({ data }) => {
  const officerRecord = await api(OFFICER_RECORD, 'post', data);
  try{
    officerRecord.data = officerRecord?.data.map(item => {
      item.aff_info.offices = item.aff_info?.offices.map(office => {
        office.officers = office?.officers.map(officer => {
          return {
            ...officer,
            address: convertStandardAddress(officer.address)
          }
        });
        return office;
      })
      return item;
    })
  } catch {
    
  }
    

  return officerRecord;
};

export const getAff_pk = ({ data }) => {
  return api(FIND_AFF_PK, 'post', data);
};

export const getTreeHierarchy = ({ data }) => {
  return api(TREE_HIERARCHY, 'post', data);
};

export const editOfficer = ({ data }) => {
  console.log("======== editOfficer", data);
  return api(EDIT_OFFICER, 'post', data);
};

export const addOfficer = ({ data }) => {
  console.log("======== addOfficer", data);
  return api(EDIT_OFFICER, 'post', data);
};

export const getOffices = async (affPk) => {
  try {
    const response = await getOfficerRecord({ data: { aff_pk: affPk } });

    if (response?.data?.aff_info?.offices) {
      return response.data.aff_info.offices;
    }
  } catch (err) {
    notifyError(err?.message ? err.message : `Data Fetch Error ${err.status}`);
    console.error("Error", err?.message);
    return;
  }
};

export const getOfficerResponse = async (data) => {
  try {
    const response = await getOfficerRecord({ data: { aff_pk: data.aff_pk } });

    if (response?.data?.aff_info?.offices) {
      const currentOfficers = [];

      response?.data?.aff_info?.offices.forEach((element) => {
        if (element.officers.length > 0) {
          element.officers.forEach((officer) => {
            currentOfficers.push({
              ...{ ...element, officers: [] },
              ...officer,
              ...officer.office,
              chapter: response.data.aff_info.chapter,
              council: response.data.aff_info.council,
              local: response.data.aff_info.local,
              sublocal: response.data.aff_info.sublocal,
              type: response.data.aff_info.type,
              state: data.state,
            });
          });
        }
      });
      return currentOfficers;
    }
  } catch (err) {
    notifyError(err?.message ? err.message : `Data Fetch Error ${err.status}`);
    console.error("Error", err);
    return;
  }
};

export const getListAddOfficer = ({ data }) => {
  return api(LIST_ADD_OFFICER, 'post', data);
};

export const officerTransmittal = ({ data }) => {
  return api(OFFICER_TRANSMITTAL, 'post', data);
};

export const getPosition = (data) => {
  return api(LIST_ADD_OFFICER, 'post', data);
};
