import api from "services/api";
import { 
  PENDING_REQUEST_LIST, 
  RESPONSE_PENDING_REQUEST 
} from "utils/request";

export const getPendingRequestAPI = () => {
  return api(PENDING_REQUEST_LIST, 'post');
};

export const responsePendingRequest = ({ data }) => {
  return api(RESPONSE_PENDING_REQUEST, 'post', data);
};
