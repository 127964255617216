
import {
  ENUOfficeFullProfile
} from "components/recipes";
import { boolean } from "yup";

export const ENUOfficeFeed = ({
  dataOfficers,
  handleEditOfficerTitle,
  isActive,
  showModal,
  authInfo,
  isTitleHover="true",
  handleSelectOffice
}) => {
  return (
    <div className={
      `p-2 sm:p-4 text-sm md:text-base flex flex-col flex-wrap border-2 rounded-2xl mb-6 shadow-sm
        ${isTitleHover? 'lg:flex-row' : 'lg:flex-col'}
      `
    }>
        {dataOfficers?.map((item, index) => {
          return (
            <ENUOfficeFullProfile
              key={item?.aff_pk}
              item={item}
              index={index}
              handleEditOfficerTitle={handleEditOfficerTitle}
              isActive={isActive}
              showModal={showModal}
              authInfo={authInfo}
              isTitleHover={isTitleHover}
              handleSelectOffice={handleSelectOffice}
            />
          );
        })}
      </div>
  )
}