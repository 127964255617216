import { DownIcon, UpIcon } from "assets/icons/Icons";
import { Button } from "components/Button";
import React, { useEffect } from "react";
import { useState } from "react";
import { exportToExcel, formatDate } from "utils/helper";
import ModalOfficer from "./ModalOfficer";
import Modal from "components/Modal";
import { getOffices, getSelectedOfficers } from "utils/office";
// import { getOffices, getOfficerResponse } from "services/officer";
import Loading from "components/Loading";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import aff_pkActions from "redux/actions/aff_pk";
import Pill from "./Pill";
import { ENULink } from "components";

import dayjs from "dayjs";
import Tab from "./Tab";
import ModalAddOfficerTitle from "./ModalAddOfficerTitle";
import ModalEditOfficerTitle from "./ModalEditOfficerTitle";
import { ENUOfficeFeed } from "components/templates/Office/ENUOfficeFeed";
import { addOffice } from "services/office";

function InfoSubLocal({
  data,
  dataSubSelect,
  setDataSubSelect,
  dataLocalSelect,
  setDataLocalSelect,
}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const authInfo = useSelector((state) => state.auth);
  const {
    allOfficerDetailByAffPK,
    refetchData
  } = useSelector((state) => state.officer);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const [isOpenAddModalTitle, setIsOpenAddModalTitle] = useState(false);
  const openAddModalTitle = () => setIsOpenAddModalTitle(true);
  const closeAddModalTitle = () => setIsOpenAddModalTitle(false);

  const [dataEditOfficerTitle, setDataEditOfficerTitle] = useState(null);
  const [isOpenEditModalTitle, setIsOpenEditModalTitle] = useState(false);
  const openEditModalTitle = () => setIsOpenEditModalTitle(true);
  const closeEditModalTitle = () => setIsOpenEditModalTitle(false);

  const [dataOfficers, setDataOfficers] = useState([]);
  const [uuid, setUuid] = useState("");

  const [isActive, setIsActive] = useState(true);

  const handleShowDetail = async () => {
    if (!show) {
      await getDataOffice(data.aff_pk);
    }
    setShow(!show);
  };

  const [dataModal, setDataModal] = useState([]);
  const showModal = (data, uuid) => {
    setDataModal(data);
    setUuid(uuid);

    openModal();
  };

  const getDataOffice = async (affPk = data.aff_pk) => {
    setLoading(true);
    try {
      const offices = await getOffices(allOfficerDetailByAffPK, affPk);
      // const offices = await getOffices(affPk);

      const today = new Date();
      const priorYears = new Date(
        new Date().setFullYear(today.getFullYear() - 3)
      );
      const filterPastOfficer = offices.map((item) => {
        const pastOfficers = item.past_officers.filter((pastOfficer) => {
          const expriedDate = new Date(pastOfficer.pos_expiration_dt * 1000);
          return expriedDate > priorYears;
        });
        return { ...item, past_officers: pastOfficers };
      });

      const filterActiveOffice = filterPastOfficer.filter((item) => {
        return item.is_active_office;
      });
      
      setDataOfficers(filterActiveOffice);
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setLoading(true);
    const currentDate = dayjs(new Date()).format("MM-DD-YYYY");
    const fileName = `${data.short_name}-officers-${currentDate}`;
    try {
      const officers = await getSelectedOfficers(
        allOfficerDetailByAffPK,
        data
      );
      // const officers = await getOfficerResponse(data);
      await exportToExcel({ officers, authInfo, fileName });
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    dispatch(aff_pkActions.clearAff_pk());
    dispatch(aff_pkActions.addAff_pk(data.aff_pk));

    dispatch(aff_pkActions.clearShortName());
    dispatch(aff_pkActions.setShortName(data.short_name));

    navigate(`/user/edit`);
  };

  const handleChangeSelect = (event) => {
    if (
      dataSubSelect.some((sub) => sub.aff_pk === data.aff_pk) &&
      !event.target.checked
    ) {
      setDataSubSelect(
        dataSubSelect.filter((sub) => sub.aff_pk !== data.aff_pk)
      );
      setDataLocalSelect(
        dataLocalSelect.filter((sub) => sub.aff_pk !== data.aff_pk)
      );
      dispatch(aff_pkActions.removeAff_pk(data.aff_pk));

      dispatch(aff_pkActions.removeShortName(data.short_name));
    } else {
      setDataSubSelect([...dataSubSelect, data]);
      setDataLocalSelect([...dataLocalSelect, data]);
      dispatch(aff_pkActions.addAff_pk(data.aff_pk));

      dispatch(aff_pkActions.setShortName(data.short_name));
    }
  };

  const handleSetActive = () => {
    setIsActive(true);
  };

  const handleSetExpired = () => {
    setIsActive(false);
  };

  const handleEditOfficerTitle = (value) => {
    setDataEditOfficerTitle({ ...value, status: data?.status });
    openEditModalTitle();
  };

  const updateOffice = async (data) => {
    await addOffice(data);
    await getDataOffice();
  }
  return (
    <div className="px-1 pb-4 lg:px-10">
      <Loading isVisible={loading} />

      <div
        className={`${
          show && "border-green-600"
        } flex justify-between items-center border-[3px] p-2 sm:p-4 rounded-xl hover:shadow-md flex-wrap gap-2 duration-500`}
      >
        <div className="flex items-center pl-2">
          <input
            className="h-4 w-4"
            type="checkbox"
            onChange={(event) => handleChangeSelect(event)}
            checked={
              dataSubSelect.some((item) => item.aff_pk === data.aff_pk) ||
              dataLocalSelect.some((item) => item.aff_pk === data.aff_pk)
            }
          />

          <p className="text-sm sm:text-base font-bold pl-2">
            {data.short_name}
          </p>
        </div>
        {show && !dataOfficers?.length && <div>No Officer Data</div>}
        <div className="flex gap-2 items-center justify-center px-2">
          <div onClick={handleEdit}>
            <Button text="Edit" color="bg-blue-500" disabled={!isActive} />
          </div>
          <div onClick={exportExcel}>
            <Button text="Export" color="bg-green-500" disabled={!isActive} />
          </div>

          <button
            className="text-gray-700 hover:opacity-90"
            onClick={handleShowDetail}
          >
            {!show ? <DownIcon /> : <UpIcon />}
          </button>
        </div>
        {show && dataOfficers?.length > 0 && (
          <div className="border-t">
            <div className="pl-4 flex justify-between pr-4 sm:pr-10">
              <div className="pt-4">
                <Tab
                  handleSetActive={handleSetActive}
                  handleSetExpired={handleSetExpired}
                />
              </div>

              {authInfo.role === "system_admin" && (
                <div className="flex items-center" onClick={openAddModalTitle}>
                  <Button text="Create New Office" color="bg-orange-500" />
                </div>
              )}
            </div>
        
              <ENUOfficeFeed 
                dataOfficers={dataOfficers}
                isActive={isActive}
                authInfo={authInfo}
                showModal={showModal}
                handleEditOfficerTitle={handleEditOfficerTitle}
              />
            </div>
        )}
      </div>

      {isOpenModal && (
        <Modal title="Officer information" closeModal={closeModal}>
          <ModalOfficer
            data={dataModal}
            uuid={uuid}
            affInfo={data}
            isActive={isActive}
          />
        </Modal>
      )}

      {isOpenAddModalTitle && authInfo.role === "system_admin" && (
        <Modal
          title={
            <p>
              {data.short_name}{" "}
              <span className="text-sm">{`${data.name} (${data.status})`}</span>
            </p>
          }
          closeModal={closeAddModalTitle}
        >
          <ModalAddOfficerTitle
            data={data}
            closeModal={closeAddModalTitle}
            getDataOffice={getDataOffice}
          />
        </Modal>
      )}

      {isOpenEditModalTitle && authInfo.role === "system_admin" && (
        <Modal
          title={
            <p>
              {data.short_name}{" "}
              <span className="text-sm">{`${data.name} (${data.status})`}</span>
            </p>
          }
          closeModal={closeEditModalTitle}
        >
          <ModalEditOfficerTitle
            data={dataEditOfficerTitle}
            closeModal={closeEditModalTitle}
            getDataOffice={getDataOffice}
          />
        </Modal>
      )}
    </div>
  );
}

export default memo(InfoSubLocal);
