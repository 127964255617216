import { formatDate } from "utils/helper";
import Pill from "pages/user/components/Pill";

export const ENUOfficerDetailCell = ({
	ind,
	item,
	el,
	isActive,
	showModal
}) => {
	return (
		<div
			className="font-normal pl-3 text-[15px] cursor-pointer hover:text-blue-600 sm:flex flex-wrap pb-2"
			key={ind}
			onClick={() => showModal(item, el.uuid)}
		>
			<span>
				{" "}
				{`${el?.first_nm} ${el?.last_nm}`}{" "}
			</span>
			<span className="italic px-2">
				{`${formatDate(
					el?.pos_start_dt * 1000
				)} - ${el?.pos_end_dt === 0 || !el?.pos_end_dt
						? ""
						: formatDate(el?.pos_end_dt * 1000)
					}`}
			</span>
			{isActive &&
				el?.pos_expiration_dt &&
				el?.pos_expiration_dt * 1000 <
				new Date() && (
					<div className="min-w-[135px]">
						<Pill
							text={`TERM ENDED ${formatDate(
								el?.pos_expiration_dt * 1000
							)}`}
							textColor="text-white"
							bgColor="bg-red-600"
						/>
					</div>
				)}
		</div>
	)
}