import React from "react";

function Pill({ text, textColor, bgColor, border }) {
  return (
    <div
      className={`flex items-center ${border} ${bgColor} w-fit rounded-lg h-5 mt-0.5 hover:opacity-80`}
    >
      <p className={`px-2 text-[11px] ${textColor} truncate`}>{text}</p>
    </div>
  );
}

export default Pill;
