export const AFFILIATE_STATE_LIST = "list-affiliates";
export const REQUEST_USER_ACCESS = "new-access-request";
export const PENDING_REQUEST_LIST = "query-access-requests";
export const RESPONSE_PENDING_REQUEST = "dispose-access-request";
export const OFFICER_RECORD = "query-officers";
export const TREE_HIERARCHY = "list-affiliates";
export const EDIT_OFFICER = "mutate-officers";
export const LIST_ADD_OFFICER = "query-eligible-members";
export const OFFICER_TRANSMITTAL = "query-officer-transmittal";
export const FIND_AFF_PK = "get-aff_pk-for-affiliate";
export const EDIT_OFFICE = "mutate-offices";
