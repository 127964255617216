import { ENULink } from "components/ingredients";

export const ENUShowMore = ({
	showMore,
	count,
	text,
	textColor,
	style,
	handleShowMore,
	children
}) => {
	return (
		<div 
			className="sm:flex md:flex pb-3"
			style={{
				...style
			}}
		>
			{!showMore &&
				<p className="sm font-light text-sm">
					...{count} more
				</p>
			}
			<div className="-mt-0.5">
				<ENULink
					text={text}
					textColor={textColor}
					handleClick={handleShowMore}
				/>
			</div>
			{children}
		</div>
	)
}