import { ENUPill } from "components/ingredients";

export const ENUOfficerShortInfoCell = ({
  item,
  isActive,
  handleEditOfficerTitle,
  children
}) => {
  return (
    <div className="flex gap-1 flex-wrap pb-1">
      {item.seats && (
        <ENUPill
          text={`${
            item.seats === 1
              ? `${item.seats} seat`
              : `${item.seats} seats`
          }`}
          border="border border-green-10"
          textColor="text-green-10"
          handleEditOfficerTitle={() =>
            handleEditOfficerTitle(item)
          }
        />
      )}
      {item.is_elected && (
        <ENUPill
          text="elected"
          border="border border-green-10"
          textColor="text-green-10"
          handleEditOfficerTitle={() =>
            handleEditOfficerTitle(item)
          }
        />
      )}
      {item.is_executive_board && (
        <ENUPill
          text="eboard"
          textColor="text-sky-600"
          border="border border-sky-600"
          handleEditOfficerTitle={() =>
            handleEditOfficerTitle(item)
          }
        />
      )}
      {item.auto_executive_board && (
        <ENUPill
          text="auto-eboard"
          textColor="text-violet-600"
          border="border border-violet-600"
          handleEditOfficerTitle={() =>
            handleEditOfficerTitle(item)
          }
        />
      )}
      {item.is_reporting_officer && (
        <ENUPill
          text="reporting officer"
          textColor="text-yellow-600"
          border="border border-yellow-600"
          handleEditOfficerTitle={() =>
            handleEditOfficerTitle(item)
          }
        />
      )}

      {item?.term?.desc ? (
        <ENUPill
          text={item?.term?.desc
              ? `${
                item.term.desc[
                  item.term.desc.length - 1
                ] === "s"
                  ? item.term.desc.slice(
                      0,
                      item.term.desc.length - 1
                    )
                  : item.term.desc
              } term`
              : ''
          }
          textColor="text-pink-600"
          border="border border-pink-600/80"
          handleEditOfficerTitle={() =>
            handleEditOfficerTitle(item)
          }
        />
      ) : (
        <ENUPill
          text={item?.term
            ? `${
              item.term[item.term.length - 1] === "s"
                ? item.term.slice(0, item.term.length - 1)
                : item.term
            } term`
            : ''
          }
          textColor="text-pink-600"
          border="border border-pink-600/80"
          handleEditOfficerTitle={() =>
            handleEditOfficerTitle(item)
          }
        />
      )}

      {isActive &&
        item?.officers?.length > item?.seats && (
          <ENUPill
            text="over capacity"
            textColor="text-white"
            bgColor="bg-red-600"
            handleEditOfficerTitle={() =>
              handleEditOfficerTitle(item)
            }
          />
        )}
      {children}
    </div>
  )
}