import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconPaginateNext, IconPaginatePrev } from "assets/icons/Icons";
import { Button } from "components/Button";
import Layout from "components/layout";
import Loading from "components/Loading";
import Modal from "components/Modal";
import { notifyError } from "utils/helper";
import aff_pkActions from "redux/actions/aff_pk";
import { getOfficerDetailsBySelectedAffPks } from "utils/office";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import { useNavigate } from "react-router-dom";
import AddModal from "./components/AddModal";
import { exportToExcel } from "utils/helper";
import { ToastContainer } from "react-toastify";
import chunk from "lodash.chunk";
import ReactPaginate from "react-paginate";
import Offices from "./components/Offices";
import dayjs from "dayjs";
import ModalEditOfficerTitle from "../components/ModalEditOfficerTitle";

const itemsPerPage = 10;

function EditOfficers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state.auth);
  const listAff_pk = useSelector((state) => state.aff_pk.listAff_pk);
  const listShort_name = useSelector((state) => state.aff_pk.listShort_name);
  const {
    allOfficerDetailByAffPK 
  } = useSelector((state) => state.officer);

  const [loading, setLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = () => setDeleteModal(true);
  const closeDeleteModal = () => setDeleteModal(false);
  const [dataDelete, setDataDelete] = useState(null);

  const [dataEdit, setDataEdit] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const openEditModal = () => setEditModal(true);
  const closeEditModal = () => setEditModal(false);

  const [addOfficer, setAddOfficer] = useState(false);
  const openAddOfficer = () => setAddOfficer(true);
  const closeAddOfficer = () => setAddOfficer(false);

  const [dataOffices, setDataOffices] = useState([]);
  const [dataAddOfficer, setDataAddOfficer] = useState({});

  const [dataOfficerExport, setDataOfficerExport] = useState([]);

  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const [dataEditOfficerTitle, setDataEditOfficerTitle] = useState(null);
  const [isOpenEditModalTitle, setIsOpenEditModalTitle] = useState(false);
  const openEditModalTitle = () => setIsOpenEditModalTitle(true);
  const closeEditModalTitle = () => setIsOpenEditModalTitle(false);

  const handleBack = () => {
    navigate(`/user`);
  };

  const handleExport = async () => {
    const currentDate = dayjs(new Date()).format("MM-DD-YYYY");
    let fileName = `${listShort_name[0]}-officers-${currentDate}`;

    if (listShort_name?.length > 1) {
      fileName = `various-officers-${currentDate}`;
    }
    exportToExcel({ officers: dataOfficerExport, authInfo, fileName });
  };

  const getDataOfficer = useCallback(async () => {
    setLoading(true);
    try {
      const officerDetailsBySelectedAffPks = await getOfficerDetailsBySelectedAffPks(
        allOfficerDetailByAffPK,
        listAff_pk
      );
      const dataTable = [];
      const dataExcelOfficer = [];

      officerDetailsBySelectedAffPks.forEach((element) => {
        if (element.aff_info?.offices) {
          // set data table
          const listOffices = element.aff_info.offices.map((item) => {
            return {
              ...item,
              chapter: element.aff_info.chapter,
              council: element.aff_info.council,
              type: element.aff_info.type,
            };
          });
          dataTable.push(...listOffices);

          // set data excel
          element.aff_info.offices.forEach((item) => {
            if (item.officers.length > 0) {
              item.officers.forEach((officer) => {
                dataExcelOfficer.push({
                  ...{ ...element, officers: [] },
                  ...officer,
                  ...officer.office,
                  chapter: element.aff_info.chapter,
                  council: element.aff_info.council,
                  local: element.aff_info.local,
                  sublocal: element.aff_info.sublocal,
                  type: element.aff_info.type,
                  state: element.aff_info.state,
                });
              });
            }
          });
        }
      });

      const filterActiveOffice = dataTable.filter((item) => {
        return item.is_active_office;
      });
      console.log("🚀 ===== filterActiveOffice", filterActiveOffice);

      setPageCount(Math.ceil(filterActiveOffice.length / itemsPerPage));
      setCurrentPage(0);

      setDataOfficerExport(dataExcelOfficer);
      setDataOffices(filterActiveOffice);

      const itemOffset = 0;
      const endOffset = itemOffset + itemsPerPage;
      // remove pagenation
      // setCurrentItems(filterActiveOffice.slice(itemOffset, endOffset));
      setCurrentItems(filterActiveOffice);

      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
      setLoading(false);
    }
  }, [listAff_pk]);

  const handleDelete = (data) => {
    setDataDelete({
      uuid: data.uuid,
      aff_pk: data.aff_pk,
      officer_nm: `${data.first_nm} ${data.last_nm}`,
      aff_short_name: data.aff_title,
      title: data?.office?.title,
    });
    openDeleteModal();
  };

  const handleEdit = (data) => {
    console.log(data);
    setDataEdit(data);
    openEditModal();
  };

  const handleAdd = (value) => {
    setDataAddOfficer({
      office_uuid: value.office_uuid,
      aff_pk: value.aff_pk,
      length_of_term: value.term.desc,
      existOfficers: value?.officers,
    });
    openAddOfficer();
  };

  const handleEditOffice = (value) => {
    setDataEditOfficerTitle(value);
    openEditModalTitle();
  };

  useEffect(() => {
    if (listAff_pk.length > 0) {
      getDataOfficer();
    }
    return () => {};
  }, [listAff_pk, getDataOfficer]);

  useEffect(() => {
    return () => {
      dispatch(aff_pkActions.clearAff_pk([]));
    };
  }, [dispatch]);

  const handlePageClick = (page) => {
    const newOffset = (page.selected * itemsPerPage) % dataOffices.length;
    console.log("🚀 ===== newOffset", newOffset);
    const endOffset = newOffset + itemsPerPage;
    setCurrentItems(dataOffices.slice(newOffset, endOffset));
    setCurrentPage(page.selected);
    // scroll to top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Loading isVisible={loading} />
      <ToastContainer />
      <div className="container mx-auto">
        <div className="flex flex-wrap gap-2 justify-start md:p-8">
          <div onClick={handleExport}>
            <Button text={"Export"} color={"bg-green-10"} />
          </div>
          <div onClick={handleBack}>
            <Button text={"Back"} color={"bg-red-500"} />
          </div>
        </div>
        {currentItems.length > 0 && (
          <Offices
            dataOffices={currentItems}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleAdd={handleAdd}
            handleEditOffice={handleEditOffice}
          />
        )}

        {deleteModal && (
          <Modal
            title={`Delete ${dataDelete.officer_nm}?`}
            closeModal={closeDeleteModal}
          >
            <DeleteModal
              closeModal={closeDeleteModal}
              dataDelete={dataDelete}
              getDataOfficer={getDataOfficer}
            />
          </Modal>
        )}

        {editModal && (
          <Modal
            title={`Edit information of ${dataEdit?.office?.aff_title}`}
            closeModal={closeEditModal}
          >
            <EditModal
              data={dataEdit}
              closeModal={closeEditModal}
              getDataOfficer={getDataOfficer}
            />
          </Modal>
        )}

        {addOfficer && (
          <Modal title="Fill Vacancy" closeModal={closeAddOfficer}>
            <AddModal
              closeModal={closeAddOfficer}
              data={dataAddOfficer}
              getDataOfficer={getDataOfficer}
              setDataOffices={setDataOffices}
            />
          </Modal>
        )}
      </div>

      {isOpenEditModalTitle && authInfo.role === "system_admin" && (
        <Modal
          title={`${dataEditOfficerTitle.type}${dataEditOfficerTitle.chapter} - ${dataEditOfficerTitle.title}`}
          closeModal={closeEditModalTitle}
        >
          <ModalEditOfficerTitle
            data={dataEditOfficerTitle}
            closeModal={closeEditModalTitle}
            getDataOffice={getDataOfficer}
            // aff_pk={data.aff_pk}
          />
        </Modal>
      )}
      {/* remove paginate */}
      {/* <div className="flex items-center justify-center mt-24 mb-32">
        {dataOffices.length > 0 && (
          <ReactPaginate
            previousLabel={<IconPaginatePrev active={currentPage !== 0} />}
            nextLabel={
              <IconPaginateNext active={currentPage !== pageCount - 1} />
            }
            breakLabel="..."
            breakClassName=""
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="flex flex-row items-center justify-center text-sm font-semibold"
            activeClassName="bg-blue-10 box-border w-[40px] h-[40px] text-center flex justify-center item-center"
            activeLinkClassName="text-white"
            pageLinkClassName="p-[10px] flex justify-center item-center"
            pageClassName="box-border w-[40px] h-[40px] text-center"
            nextClassName={currentPage !== 0 ? "ml-[24px]" : "ml-[12px]"}
            previousClassName={
              currentPage !== pageCount - 1 ? "mr-[24px]" : "mr-[12px]"
            }
          />
        )}
      </div> */}
    </>
  );
}

export default EditOfficers;
