import React from "react";
import Form from "./components/Form";

function ForgotPassword() {
  return (
    <div className="relative bg-img h-screen min-h-[650px]">
      <div className="w-full h-screen min-h-[650px] bg-blue-600 opacity-60 absolute" />
      <Form />
    </div>
  );
}

export default ForgotPassword;
