/** @format */

import { Button, ButtonActionTable } from "components/Button";
import Modal from "components/Modal";
import React, { useEffect, useState } from "react";
import {
  checkHomeAddress,
  checkMailingAddress,
  checkErrorField,
  getShortErrorMessage,
} from "utils/helper";
import EditExistOfficerModal from "./EditExistOfficerModal";
import { 
  ENUOfficeFullProfile, 
  ENUModal, 
  ENUSelectOfficerModal, 
  ENULink,
  ENUPill,
  ENUButton
} from "components";

function TableOfficer({
  officers,
  type,
  setDataOfficer,
  handleSubmit,
  handleLoadingFile,
  getUpdatedOffice
}) {
  const [dataEditExistOfficer, setDataEditExistOfficer] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const closeEditModal = () => setEditModal(false);
  const openEditModal = () => setEditModal(true);
  const [openOfficerSelect, setOpenOfficerSelect] = useState(false);

  const handleDelete = (officer) => {
    const removeOfficer = officers.filter((item) => {
      return item.id !== officer.id;
    });
    setDataOfficer(removeOfficer);
  };

  const handleEditOfficer = (officer) => {
    setDataEditExistOfficer(officer);
    openEditModal();
  };

  const hadleSelectPreferredOffice = async (preferredOffice) => {
    const dataTableUpdate = await handleLoadingFile(
      officers.map(officer => 
        officer === dataEditExistOfficer
        ? {...officer, preferred_office: preferredOffice}
        : officer
      ), 
      true
    );
    setDataOfficer(dataTableUpdate);
    setOpenOfficerSelect(false);
  }

  return (
    <div className="min-w-[1700px] max-h-[70vh]">
      <table className="table-fixed">
        <thead className="text-xs text-black-10/80 h-14">
          <tr className="">
            <th className="border w-40"></th>
            <th className="border w-24">Errors</th>
            <th className="border w-24">State</th>
            <th className="border w-52">Type / Council / Local / Subunit</th>
            <th className="border w-80">Title</th>
            <th className="border w-24">Name</th>
            <th className="border w-24">ID</th>
            <th className="border w-24">Start Date</th>
            <th className="border w-24">Expiration Date</th>
            <th className="border w-56">Address</th>
            <th className="border w-52">Phone</th>
          </tr>
        </thead>
        <tbody className="text-xs text-center">
          {officers?.map((officer, index) => {
            return (
              <tr key={index}>
                {/* action */}
                <td className="border-l border-b bg-white py-4">
                  <div className="flex justify-center items-center space-x-2">
                    {officer.errors?.length ? (
                      <div onClick={() => handleDelete(officer)}>
                        <ButtonActionTable
                          text={"Reject"}
                          color={"bg-red-500"}
                        />
                      </div>
                    ) : !officer.isSubmit ? (
                      <div onClick={() => handleSubmit(officer, true)}>
                        <ButtonActionTable
                          text={"Submit"}
                          color={"bg-green-10"}
                          disabled={officer.isSubmit}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-center text-green-10 font-medium">
                          Imported
                        </div>
                      </>
                    )}
                  </div>
                </td>
                {/* errors */}
                <td
                  className={`border bg-white relative ${
                    officer.errors.length && "group"
                  }`}
                >
                  {officer.errors.length > 0 && (
                    <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                      {`${officer.errors.length} ${
                        officer.errors.length < 2 ? "error" : "errors"
                      }`}
                    </p>
                  )}
                  <div className="hidden absolute top-0 left-0 z-50 bg-red-500 text-white group-hover:flex w-80 text-left m-auto justify-start items-start p-2 flex-col min-h-full flex-wrap rounded-r-lg">
                    {/* aff_pk error */}
                    {officer?.errors?.map((el, index) => (
                      <p key={index} className="pt-1">
                        - {el.message}
                      </p>
                    ))}
                  </div>
                </td>

                {/* state */}
                <td className="border p-1">
                  {officer?.state && <p className="mb-1">{officer?.state}</p>}
                  {checkErrorField(officer, "state") && (
                    <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                      {getShortErrorMessage(officer, "state")}
                    </p>
                  )}
                </td>
                {/* Type/Council/Local/Subunit */}
                <td className={`border p-1`}>
                  {officer?.aff_type && (
                    <p className="mb-1">
                      {officer.aff_type}
                      {officer.council ? `/ ${officer.council}` : ""}
                      {officer.local ? `/ ${officer.local}` : ""}
                      {officer.subunit ? `/ ${officer.subunit}` : ""}
                    </p>
                  )}
                  {checkErrorField(officer, "aff_type") && (
                    <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                      {getShortErrorMessage(officer, "aff_type")}
                    </p>
                  )}
                </td>

                {/* Officer Title */}
                <td className={`border p-1`}>
                  <div className="relative group">
                    {officer?.officer_title && (
                      <p className='mb-1'>
                        {officer?.officer_title}
                      </p>
                    )}
                    <div className="
                      hidden
                      absolute 
                      top-6 
                      bg-green-100 
                      text-black 
                      group-hover:block 
                      group-hover:duration-1000 
                      w-80 p-1 
                      rounded-lg 
                      border-2 
                      border-red
                      min-w-400
                      z-10"
                    >
                      <ENUOfficeFullProfile
                        item={
                          officer?.existing_officer
                          ? {
                              ...officer?.existing_officer?.office, 
                              title: officer?.officer_title
                            }
                          : officer?.preferred_office
                        }
                        index={index}
                        isActive={true}
                        showModal={true}
                      />
                    </div>
                  </div>
                  {!officer?.existing_officer && officer?.matched_offices?.length > 1 &&
                    <div
                      className="mt-2 m-auto"
                    >
                      <ENULink
                        text={`${officer.matched_offices.length} matching offices...`}
                        textColor="text-blue-600"
                        bgColor="bg-blue-500"
                        handleClick={() => {
                          setDataEditExistOfficer(officer);
                          setOpenOfficerSelect(true)}
                        }
                      />
                    </div>
                  }
                  {checkErrorField(officer, "officer_title") && (
                    <>
                      <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                        {getShortErrorMessage(officer, "officer_title")}
                      </p>
                      {getShortErrorMessage(officer, "officer_title") ===
                        "No Available Seat" && (
                        <div
                          className="mt-2"
                        >
                          <ENULink
                            text="vacate current..."
                            textColor="text-blue-600"
                            bgColor="bg-blue-500"
                            handleClick={() => handleEditOfficer(officer)}
                          />
                        </div>
                      )}
                    </>
                  )}
                </td>
                {/* 	Officer Name */}
                <td className="border p-1">
                  {officer?.officer_name && (
                    <p className="mb-1">{officer?.officer_name}</p>
                  )}

                  {checkErrorField(officer, "officer_name") && (
                    <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                      {getShortErrorMessage(officer, "officer_name")}
                    </p>
                  )}
                </td>
                {/* Enterprise ID */}
                <td className="border p-1">
                  {officer?.enterprise_ID && (
                    <p className="mb-1">{officer?.enterprise_ID}</p>
                  )}

                  {checkErrorField(officer, "enterprise_ID") && (
                    <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                      {getShortErrorMessage(officer, "enterprise_ID")}
                    </p>
                  )}
                </td>
                {/* Election Date - start date */}
                <td className="border p-1">
                  {officer?.election_date && (
                    <p className="mb-1">{officer?.election_date}</p>
                  )}
                  {checkErrorField(officer, "election_date") && (
                    <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                      {getShortErrorMessage(officer, "election_date")}
                    </p>
                  )}
                </td>
                {/* Expiration Date */}
                <td className="border p-1">
                  {officer?.expiration_date && (
                    <p className="mb-1">{officer?.expiration_date}</p>
                  )}
                </td>
                {/* address */}
                <td className="border px-2 py-2">
                  {checkHomeAddress(officer) || checkMailingAddress(officer) ? (
                    <>
                      {checkHomeAddress(officer) && (
                        <div className="mb-2 text-left">
                          <p>{officer?.home_address.street}</p>
                          <p>
                            {officer?.home_address.city}{" "}
                            {officer?.home_address.state}{" "}
                            {officer?.home_address.zip}{" "}
                          </p>
                        </div>
                      )}
                      {checkMailingAddress(officer) && (
                        <div className="mb-2 text-left">
                          <p>{officer?.mailing_address.street}</p>
                          <p>
                            {officer?.mailing_address.city}{" "}
                            {officer?.mailing_address.state}{" "}
                            {officer?.mailing_address.zip}{" "}
                          </p>
                        </div>
                      )}
                      {checkMailingAddress(officer) &&
                      officer?.send_mail === "Y" ? (
                        <div className="flex items-center border-gray-400 border-2 w-fit rounded-lg h-5">
                          <p className="px-2 text-gray-500">
                            send mail to office
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <p className="inline-block bg-red-500 text-white text-xs whitespace-nowrap rounded-lg px-2 py-1">
                      {getShortErrorMessage(officer, "address")}
                    </p>
                  )}
                </td>
                {/* Phone */}
                <td className="border p-1">
                  {officer?.primary_phone_number && (
                    <p className="py-2 whitespace-nowrap">
                      {officer?.primary_phone_number}
                      {officer.primary_phone_type && (
                        <span>{` (${officer.primary_phone_type})`}</span>
                      )}
                    </p>
                  )}
                  {officer?.secondary_phone_number && (
                    <p className="whitespace-nowrap">
                      {officer?.secondary_phone_number}
                      {officer.secondary_phone_type && (
                        <span>{` (${officer.secondary_phone_type})`}</span>
                      )}
                    </p>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {editModal && (
        <Modal closeModal={closeEditModal} title="Edit officer">
          <EditExistOfficerModal
            closeModal={closeEditModal}
            dataEdit={dataEditExistOfficer}
            officers={officers}
            setDataOfficer={setDataOfficer}
            handleLoadingFile={handleLoadingFile}
          />
        </Modal>
      )}
      <ENUSelectOfficerModal 
        open={openOfficerSelect} 
        setOpen={setOpenOfficerSelect}
        title={"Select Office"}
        officer={dataEditExistOfficer}
        handleSelectOffice={hadleSelectPreferredOffice}
      />
    </div>
  );
}

export default TableOfficer;
