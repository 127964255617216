export const ENUEditOfficerDateField = ({
    register,
    title,
    registerId,
    disabled,
    errorEndDate,
    errors
  }) => {
    return (
      <div className="relative sm:flex pb-3">
        <p className="sm:min-w-[150px]">{title}:</p>
        <input
          type="date"
          className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
          disabled={disabled}
          {...register(registerId)}
        />
        {!disabled && errors?.registerId && (
          <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
            {errors?.pos_end_dt?.message}
          </p>
        )}
        {!disabled && errorEndDate && (
          <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
            Day must be before today
          </p>
        )}
      </div>
    )
  }