import {
  loginActionTypes,
  logoutActionType,
  roleActionType,
} from "utils/constants";

const loginRequest = (payload) => ({
  type: loginActionTypes.LOGIN_REQUEST,
  payload,
});

const loginFailed = (payload) => ({
  type: loginActionTypes.LOGIN_FAILED,
  payload,
});

const loginSuccess = (payload) => ({
  type: loginActionTypes.LOGIN_SUCCESS,
  payload,
});

const logout = (payload) => ({
  type: logoutActionType.LOGOUT,
  payload,
});

const changeRoles = (payload) => ({
  type: roleActionType.CHANGE_ROLES,
  payload,
});

const authActions = {
  loginRequest,
  loginSuccess,
  loginFailed,
  logout,
  changeRoles,
};

export default authActions;
