import React from "react";

export const CloseIcon = ({ size }) => (
  <>
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </>
);
export const HomeIcon = () => (
  <>
    <svg
      viewBox="0 0 24 24"
      width="26"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </svg>
  </>
);

export const SignOutIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={26}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
      <polyline points="16 17 21 12 16 7" />
      <line x1={21} y1={12} x2={9} y2={12} />
    </svg>
  );
};

export const MenuIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={36}
      height={36}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <line x1={3} y1={12} x2={21} y2={12} />
      <line x1={3} y1={6} x2={21} y2={6} />
      <line x1={3} y1={18} x2={21} y2={18} />
    </svg>
  );
};

export const IconAdmin = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={26}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
      <circle cx={12} cy={7} r={4} />
    </svg>
  );
};

export const SettingIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={26}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <circle cx={12} cy={12} r={3} />
      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
    </svg>
  );
};

export const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx={12}
        cy={12}
        r={10}
        stroke="currentColor"
        strokeWidth={4}
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      aria-hidden="true"
      className="mr-3 flex-none"
    >
      <path
        d="m19 19-3.5-3.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={11}
        cy={11}
        r={6}
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DownIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <polyline points="6 9 12 15 18 9" />
    </svg>
  );
};

export const UpIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <polyline points="18 15 12 9 6 15" />
    </svg>
  );
};

export const ListIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <line x1={8} y1={6} x2={21} y2={6} />
      <line x1={8} y1={12} x2={21} y2={12} />
      <line x1={8} y1={18} x2={21} y2={18} />
      <line x1={3} y1={6} x2="3.01" y2={6} />
      <line x1={3} y1={12} x2="3.01" y2={12} />
      <line x1={3} y1={18} x2="3.01" y2={18} />
    </svg>
  );
};

export const DataIcon = ({ size }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
      <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
    </svg>
  );
};

export const CheckIcon = ({ size = 24 }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
};

export const XSquareIcon = ({ size = 24 }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <rect x={3} y={3} width={18} height={18} rx={2} ry={2} />
      <line x1={9} y1={9} x2={15} y2={15} />
      <line x1={15} y1={9} x2={9} y2={15} />
    </svg>
  );
};

export const IconPaginatePrev = ({ active }) => {
  if (active)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
      >
        <path d="M0 7L7 0V3.5L3.5 7L7 10.5V14L0 7Z" fill="#22AED1" />
      </svg>
    );
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="none"
    >
      <path d="M0 7L7 0V3.5L3.5 7L7 10.5V14L0 7Z" fill="#8B8C9B" />
    </svg>
  );
};

export const IconPaginateNext = ({ active }) => {
  if (active)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
      >
        <path d="M7 7L0 14V10.5L3.5 7L0 3.5V0L7 7Z" fill="#22AED1" />
      </svg>
    );
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="none"
    >
      <path d="M7 7L0 14V10.5L3.5 7L0 3.5V0L7 7Z" fill="#8B8C9B" />
    </svg>
  );
};

export const IconUser = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={26}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
      <circle cx={9} cy={7} r={4} />
      <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    </svg>
  );
};

export const WhiteLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.144438mm"
      height="7.0188885mm"
      viewBox="0 0 17.144438 7.0188885"
      version="1.1"
      id="svg3116"
    >
      <defs id="defs3110" />
      <g id="layer1" transform="translate(-155.46944,-82.669127)">
        <g
          id="g2622"
          style={{ fill: "#ffffff", fillOpacity: 1 }}
          transform="translate(104.34943,21.005397)"
        >
          <path
            id="Path_851-1"
            data-name="Path 851"
            className="cls-1"
            d="m 54.618803,68.646006 h -0.888435 l -0.132444,-0.584723 h -1.24694 l -0.342537,0.584723 h -0.888435 l 1.779062,-2.875399 h 0.959197 l 0.760532,2.875399 m -1.130465,-1.08256 -0.234829,-1.050624 -0.610555,1.050624 z"
            style={{ fill: "#ffffff", fillOpacity: 1, strokeWidth: "0.156552" }}
          />
          <path
            id="Path_852-8"
            data-name="Path 852"
            className="cls-1"
            d="m 56.624084,67.481256 h -1.194495 l -0.205554,1.16475 h -0.86777 l 0.507073,-2.875399 h 2.062266 l -0.102698,0.577835 h -1.192304 l -0.09816,0.554979 h 1.192149 l -0.100507,0.577835"
            style={{ fill: "#ffffff", fillOpacity: 1, strokeWidth: "0.156552" }}
          />
          <path
            id="Path_853-7"
            data-name="Path 853"
            className="cls-1"
            d="m 58.784664,67.810016 a 0.92444234,0.92444234 0 0 1 -0.221521,0.4659 1.1000942,1.1000942 0 0 1 -0.438347,0.30152 1.5655247,1.5655247 0 0 1 -0.584724,0.105047 c -0.05933,0 -0.132443,-0.0045 -0.216982,-0.01143 a 2.2252368,2.2252368 0 0 1 -0.333456,-0.06387 2.410908,2.410908 0 0 1 -0.420187,-0.152952 l 0.123363,-0.705738 a 2.6247587,2.6247587 0 0 0 0.390599,0.22387 1.0417001,1.0417001 0 0 0 0.436155,0.08908 0.45932495,0.45932495 0 0 0 0.30152,-0.07311 0.25925089,0.25925089 0 0 0 0.08908,-0.136983 0.19506438,0.19506438 0 0 0 -0.07828,-0.196474 1.3482299,1.3482299 0 0 0 -0.29745,-0.16438 2.0730678,2.0730678 0 0 1 -0.376821,-0.216981 0.75317393,0.75317393 0 0 1 -0.239839,-0.294632 0.67834185,0.67834185 0 0 1 -0.03883,-0.417995 0.93352238,0.93352238 0 0 1 0.187863,-0.420187 1.0060062,1.0060062 0 0 1 0.402027,-0.299172 1.449989,1.449989 0 0 1 0.593803,-0.111935 1.9678645,1.9678645 0 0 1 0.436156,0.04571 1.74556,1.74556 0 0 1 0.31968,0.105046 1.027767,1.027767 0 0 1 0.166728,0.09143 l -0.118823,0.673176 a 1.8786296,1.8786296 0 0 0 -0.353965,-0.226062 0.99222955,0.99222955 0 0 0 -0.447584,-0.100507 0.41846475,0.41846475 0 0 0 -0.246727,0.06168 0.25048395,0.25048395 0 0 0 -0.100506,0.152951 0.15404763,0.15404763 0 0 0 0.04791,0.14622 1.0191566,1.0191566 0 0 0 0.23295,0.150761 3.8314652,3.8314652 0 0 1 0.481868,0.280855 0.80092244,0.80092244 0 0 1 0.262695,0.287743 0.67020112,0.67020112 0 0 1 0.04117,0.411107"
            style={{ fill: "#ffffff", fillOpacity: 1, strokeWidth: "0.156552" }}
          />
          <path
            id="Path_854-9"
            data-name="Path 854"
            className="cls-1"
            d="m 61.472514,68.428868 a 2.4810435,2.4810435 0 0 1 -0.390599,0.148412 2.634465,2.634465 0 0 1 -0.390598,0.08 3.2586397,3.2586397 0 0 1 -0.39733,0.02286 1.899138,1.899138 0 0 1 -0.655485,-0.102699 1.1530089,1.1530089 0 0 1 -0.463552,-0.30606 1.021818,1.021818 0 0 1 -0.244379,-0.472788 1.4780119,1.4780119 0 0 1 -0.0045,-0.593804 1.7774967,1.7774967 0 0 1 0.244379,-0.650945 1.618596,1.618596 0 0 1 0.443043,-0.463552 1.8499805,1.8499805 0 0 1 0.575487,-0.271775 2.31228,2.31228 0 0 1 0.639517,-0.08908 3.0587222,3.0587222 0 0 1 1.071132,0.219173 l -0.134792,0.765072 a 2.5482046,2.5482046 0 0 0 -0.38371,-0.221522 1.1306219,1.1306219 0 0 0 -0.47498,-0.08908 1.2453749,1.2453749 0 0 0 -0.486409,0.100507 1.1177846,1.1177846 0 0 0 -0.402026,0.283203 0.84961025,0.84961025 0 0 0 -0.207902,0.431615 0.65329346,0.65329346 0 0 0 0.04571,0.399679 0.63497682,0.63497682 0 0 0 0.271775,0.287743 0.89548013,0.89548013 0 0 0 0.447584,0.107395 1.6124904,1.6124904 0 0 0 0.407036,-0.05025 1.945321,1.945321 0 0 0 0.333457,-0.114127 3.1565675,3.1565675 0 0 0 0.287744,-0.148412 l -0.130252,0.728595"
            style={{ fill: "#ffffff", fillOpacity: 1, strokeWidth: "0.156552" }}
          />
          <path
            id="Path_855-2"
            data-name="Path 855"
            className="cls-1"
            d="m 64.882383,68.646006 h -0.867927 l 0.294632,-1.667127 -0.952309,0.966085 h -0.06857 l -0.614312,-0.966085 -0.294631,1.667127 h -0.842722 l 0.507073,-2.875399 h 0.787929 l 0.69431,1.07802 1.07802,-1.07802 h 0.787929 l -0.509265,2.875399"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: "0.156552",
            }}
          />
          <path
            id="Path_856-0"
            data-name="Path 856"
            className="cls-1"
            d="M 67.170711,68.646006 H 65.00105 l 0.509265,-2.875399 h 2.133028 l -0.102855,0.577835 h -1.265101 l -0.09393,0.527582 h 1.208115 l -0.102698,0.577835 h -1.208116 l -0.107081,0.614312 h 1.301733 l -0.102855,0.577835"
            style={{ fill: "#ffffff", fillOpacity: 1, strokeWidth: "0.156552" }}
          />
          <path
            id="Path_857-2"
            data-name="Path 857"
            className="cls-1"
            d="m 66.951537,62.627973 c -0.888435,-0.253459 -3.133398,0.376822 -4.910268,1.32005 -0.351773,0.187863 -1.128274,0.62621 -1.363416,0.799357 l 0.08219,0.07311 c 0.554979,-0.322028 0.701199,-0.390598 0.751452,-0.417995 1.872681,-1.055164 3.948723,-1.505095 4.837472,-1.25148 0.504725,0.143871 0.488756,0.518501 0.05714,0.98628 0.961546,-0.701198 1.226432,-1.315041 0.545899,-1.509635 m -0.488757,1.095867 c 0,-0.0045 0.0023,-0.01143 0.0023,-0.01566 v -0.01378 l -0.0023,0.0023 c 0.0023,-0.100506 -0.08,-0.180348 -0.262695,-0.239838 -0.680534,-0.216982 -2.461944,0.207902 -3.880153,0.908944 a 7.3609406,7.3609406 0 0 0 -0.653137,0.36007 l 0.07828,0.08 c 0.0274,-0.01378 0.05714,-0.0274 0.08454,-0.04337 1.349795,-0.735797 3.199619,-1.125926 3.880153,-0.908944 0.30152,0.09597 0.317488,0.212442 0.140897,0.431615 l -0.132444,0.127904 -2.363942,0.01832 2.009821,0.433964 -0.582375,0.696502 0.991133,-0.422692 0.523042,0.422692 0.06622,-0.698851 0.934148,-0.454002 -0.892349,0.0045 z m 1.362946,-1.995261 c -1.029959,-0.283204 -3.448538,0.385902 -5.6935,1.610142 -0.566407,0.308252 -1.998393,1.15567 -2.464293,1.491319 l 0.102699,0.04571 c 0.970625,-0.55263 1.297193,-0.758184 1.722077,-0.988942 2.076042,-1.130465 4.595128,-1.820235 5.625087,-1.537032 0.584723,0.15984 0.563588,0.598344 0.05714,1.15567 1.125926,-0.83552 1.441066,-1.559888 0.650789,-1.77687 z"
            style={{ fill: "#ffffff", fillOpacity: 1, strokeWidth: "0.156552" }}
          />
        </g>
      </g>
    </svg>
  );
};

export const IconArrowLeft = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={36}
      height={36}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <line x1={19} y1={12} x2={5} y2={12} />
      <polyline points="12 19 5 12 12 5" />
    </svg>
  );
};

export const IconFile = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
      <polyline points="14 2 14 8 20 8" />
      <line x1={16} y1={13} x2={8} y2={13} />
      <line x1={16} y1={17} x2={8} y2={17} />
      <polyline points="10 9 9 9 8 9" />
    </svg>
  );
};

export const IconEdit = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
    </svg>
  );
};
